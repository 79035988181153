import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useAppState } from '../AppStateContext';
import { styles } from '../styles/styles.js';
import { useNavigate } from 'react-router-dom';

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={
        styles.cardbutton_solo
    }
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', margin: 0 }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const { userProfile, userTier, setUserProfile, setUserTier, fetchUserProfile } = useAppState();
  const [promoCode, setPromoCode] = useState('');
  const [tutorialMode, setTutorialMode] = useState(
    localStorage.getItem('tutorialMode') === 'true'
  );
  const navigate = useNavigate();

  useEffect(() => {
    const loadProfile = async () => {
      setLoading(true);
      await fetchUserProfile(); 
      setLoading(false);
    };
  
    loadProfile();
  }, [fetchUserProfile]); 

  const upgradeSubscription = async () => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      const { data } = await axios.post(`${url}/profile/upgrade/`, 
        { code: promoCode },
        { headers: { Authorization: `Token ${token}` }}
      );
      setUserProfile(data);
      const tier = data.subscriptions?.tier || 'Free';
      setUserTier(tier);
      localStorage.setItem('userTier', tier);
      navigate('/profile');
    } catch (error) {
      console.error('Error upgrading subscription:', error);
    }
  };

  const downgradeSubscription = async () => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      const { data } = await axios.post(`${url}/profile/downgrade/`, {}, {
        headers: { Authorization: `Token ${token}` }
      });
      setUserProfile(data);
      const tier = data.subscriptions?.tier || 'Free';
      console.log('User tier: ', tier);
      setUserTier(tier);
      localStorage.setItem('userTier', tier);
      navigate('/profile');
    } catch (error) {
      console.error('Error downgrading subscription:', error);
    }
  };

  const buyProSubscription = async () => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken');
      const { data } = await axios.post(
        `${url}/create-checkout-session/`,
        {},
        { headers: { Authorization: `Token ${token}` }}
      );
      
      // Redirect to Stripe Checkout
      window.location.href = data.url;
      
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handleChangePassword = () => {
    navigate('/passwordchangerequest');
  };

  const handleTutorialMode = () => {
    const newMode = !tutorialMode;
    setTutorialMode(newMode);
    localStorage.setItem('tutorialMode', newMode);
  };

  const handleUnlockedFixtures = () => {
    navigate('/unlockedgames');
  };

  const handleUpgradeSubscription = () => {
    upgradeSubscription();
    navigate('/profile');
  };

  const handleBuyProSubscription = () => {
    buyProSubscription();
    navigate('/profile');
  };

  const handleCancelSubscription = () => {
    downgradeSubscription();
    navigate('/profile');
  };

  // const handleFavoritedFixtures = () => {
  //   // TODO: Implement favorited fixtures view
  //   console.log('Favorited fixtures clicked');
  // };

  if (loading) {
    return (
      <Container>
        <Col>
          <p style={{ color: 'white' }}>Loading...</p>
        </Col>
      </Container>
    );
  }

  return (
    <div>
        {userProfile && (
          <div className="mb-4">
            <h2 style={{ color: 'white', marginBottom: '20px', marginLeft: '1rem' }}>Profile Information</h2>
            <ul style={{ color: 'white', listStyleType: 'none', padding: 0, marginLeft: '1rem' }}>
              <li>Username: {userProfile.user}</li>
              <li>ID: {userProfile.id}</li>
              <li>Last Login: {new Date(userProfile.last_login_date).toLocaleString()}</li>
              <li>Subscriptions: {JSON.stringify(userProfile.subscriptions)}</li>
              <li>Settings: {JSON.stringify(userProfile.settings)}</li>
              <li>Can Unlock: {userProfile.can_unlock ? 'Yes' : 'No'}</li>
              <li>Last Unlock: {userProfile.last_unlock ? new Date(userProfile.last_unlock).toLocaleString() : 'Never'}</li>
              <li>Karma: {userProfile.karma}</li>
              <li>Tier: {userTier}</li>
            </ul>
          </div>
        )}

        <CardButton 
          title="Change Password"
          onClick={handleChangePassword}
        />
        
        <CardButton 
          title={`Tutorial Mode: ${tutorialMode ? 'Enabled' : 'Disabled'}`}
          onClick={handleTutorialMode}
        />
        
        {userTier === 'Free' && (
          <CardButton 
            title="Unlocked Fixtures"
            onClick={handleUnlockedFixtures}
          />
          )
        }

        { userTier === 'Free' && (
          <>
            <CardButton 
              title="Upgrade Subscription With Code"
              onClick={handleUpgradeSubscription}
            />
            <div style={{ padding: '0 1rem', marginBottom: '1rem' }}>
              <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter promotion code"
                style={{
                  width: '20%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  backgroundColor: 'black',
                  color: 'white',
                }}
              />
            </div>
          </>
        )}

        { userTier === 'Free' && (
          <>
            <CardButton 
              title="Buy Pro Subscription"
              onClick={handleBuyProSubscription}
            />
          </>
        )}

        { userTier === 'Pro' && (
          <CardButton 
            title="Cancel Subscription"
            onClick={handleCancelSubscription}
          />
          )
        }

        
        {/* <CardButton 
          title="Favorited Fixtures"
          onClick={handleFavoritedFixtures}
        /> */}
        </div>
  );
};

export default Profile;