import React, { useState } from "react";
import Versus from "./Cards/versus.js";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Comments from "./Cards/comments.js";
import Unlock from "./Cards/unlock.js";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";
import { useNavigate } from "react-router-dom";
import * as motion from "motion/react-client";

const Game = ({ fixture, isAuthenticated }) => {
  const { isTutorialMode, userTier, userProfile } = useAppState();
  const navigate = useNavigate();
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);

  const isAuthenticatedAndPro = isAuthenticated && userTier === 'Pro';
  const isAuthenticatedAndFree = isAuthenticated && userTier === 'Free';
  const unlockedFixtures = userProfile && (userProfile.unlocked_fixtures || null);
  const isFixtureUnlocked = unlockedFixtures ? unlockedFixtures.includes(fixture.id) : false;

  const handleClick = () => {
    navigate(`/fixture/${fixture.id}`, { state: { fixture, isTutorialMode } });
  };

  const handleCommentClick = (e) => {
    e.stopPropagation();
    if (isAuthenticated) {
      setIsCommentsOpen(true);
    }
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0}}
        transition={{
          duration: 0.5,
          scale: { type: "spring", damping: 10, stiffness: 100, bounce: 0.5 }
        }}
      >
        <Card className="main" style={styles.main}>
          <Card.Body>
            <Card.Title>
              <b>
                {" "}
                <Versus fixture={fixture} /> at{" "}
                {convertUTCDateToLocalDateTime(fixture.game_time)}
              </b>
            </Card.Title>
            <Table className="main" bordered onClick={handleClick}>
              <tbody>
                <tr>
                  <td className="Odds" style={styles.Odds}> Home </td>
                  <td className="Odds" style={styles.Odds}> {fixture.home_team} </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.home_spread === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.home_spread} ({fixture.home_spread_price})
                  </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.home_ml === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.home_moneyline}
                  </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.over === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.over} ({fixture.over_price})
                  </td>
                </tr>
                <tr>
                  <td className="Odds" style={styles.Odds}> Away </td>
                  <td className="Odds" style={styles.Odds}> {fixture.away_team} </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.away_spread === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.away_spread} ({fixture.away_spread_price})
                  </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.away_ml === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.away_moneyline}
                  </td>
                  <td className="Odds" style={fixture.recent && fixture.recent.under === "True" ? styles.Suggested : styles.Odds}>
                    {fixture.under} ({fixture.under_price})
                  </td>
                </tr>
              </tbody>
            </Table>
            
            {fixture.comment_count > 0 && (
              <>
                <span 
                  style={{ 
                    marginLeft: '10px', 
                    fontSize: 'clamp(15px, calc(8px + (4) * ((100vw - 300px) / (1200 - 320))), 40px)',
                    cursor: 'pointer' 
                  }}
                  onClick={handleCommentClick}
                >
                  💬 {fixture.comment_count}
                </span>
                {isCommentsOpen && (
                  isAuthenticatedAndPro || (isAuthenticatedAndFree && isFixtureUnlocked) ? (
                    <Comments 
                      fixture={fixture}
                      isAuthenticated={isAuthenticated}
                      isOpen={isCommentsOpen}
                      toggle={() => setIsCommentsOpen(!isCommentsOpen)}
                    />
                  ) : (
                    <Unlock
                      fixture={fixture}
                      onClose={() => setIsCommentsOpen(false)}
                    />
                  )
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </motion.div>
    </div>
  );
};

export default Game;