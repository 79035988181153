import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, Card, CardBody, CardText } from 'reactstrap';
import { styles } from "../../styles/styles.js";
import moment from 'moment'; 
import ReactMarkdown from 'react-markdown';

const Comments = ({ fixture, isAuthenticated, isOpen, toggle }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/comments/?fixture=${fixture.id}`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };
  
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen, fixture.id]);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/comments/`, {
          fixture: fixture.id,
          text: newComment,
          parent: replyingTo ? replyingTo.id : null
        }, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          }
        });
        setComments([...comments, response.data]);
        setNewComment('');
        setReplyingTo(null);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const innerCardStyle = {
    backgroundColor: "black",
    color: "white",
    borderColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    padding: '10px',
    width: '80%'  // This will make it respect parent width
  };

  const formatTimeSince = (dateString) => {
    return moment(dateString).fromNow();
  };

  const handleReplyClick = (comment) => {
    setReplyingTo(comment);
    setNewComment(`@${comment.user} `);
  };

  const CommentHeader = ({ comment }) => (
    <div style={styles.commentHeader}>
      <span style={styles.username}>{comment.user}</span>
      <span style={styles.timestamp}>{formatTimeSince(comment.created_at)}</span>
      {!comment.parent && (
        <span 
          style={{...styles.timestamp, marginLeft: '20px', cursor: 'pointer'}} 
          onClick={() => handleReplyClick(comment)}
        >
          reply
        </span>
      )}
    </div>
  );

const SingleComment = ({ comment, isReply = false }) => (
  <Card style={{
    ...innerCardStyle,
    marginBottom: '1rem',
    marginLeft: isReply ? '2rem' : '0',
    width: isReply ? 'calc(100% - 2rem)' : '100%'
  }}>
    <CardBody>
      <CommentHeader comment={comment} />
      <CardText>
        <ReactMarkdown>{comment.text}</ReactMarkdown>
      </CardText>
    </CardBody>
  </Card>
);

  const renderComments = () => {
    const topLevelComments = comments.filter(comment => !comment.parent);
    const replies = comments.filter(comment => comment.parent);

    return topLevelComments.map(comment => (
      <div key={comment.id}>
        <SingleComment comment={comment} />
        {replies
          .filter(reply => reply.parent === comment.id)
          .map(reply => (
            <SingleComment key={reply.id} comment={reply} isReply={true} />
          ))
        }
      </div>
    ));
  };

  if (!isAuthenticated || !isOpen) return null;

  return (
    <div style={{
      marginTop: '1rem',
    }}>
      <div style={{ marginBottom: '2rem' }}>
        {renderComments()}
      </div>

      <div style={{ position: 'relative', marginTop: '2rem' }}>
        {replyingTo && (
          <div style={{
            position: 'absolute',
            top: '-25px',
            left: '0',
            color: '#888',
            fontSize: '0.9em'
          }}>
            Replying to {replyingTo.user}
            <span 
              style={{marginLeft: '10px', cursor: 'pointer'}} 
              onClick={() => {
                setReplyingTo(null);
                setNewComment('');
              }}
            >
              ✕
            </span>
          </div>
        )}
        <Input
          type="textarea"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          style={{
            backgroundColor: "black",
            color: "white",
            height: '30vh',
            marginBottom: '1rem',
            resize: 'none',
            width: '100%',
            borderColor: "white",
            borderWidth: 1,
            borderStyle: "solid",
            padding: '10px'
          }}
        />
        <div 
          onClick={handleAddComment}
          style={{
            backgroundColor: "black",
            color: "white",
            cursor: 'pointer',
            textAlign: 'center',
            width: '100%',
            borderColor: "white",
            borderWidth: 1,
            borderStyle: "solid",
            padding: '10px'
        
          }}
        >
          Post Comment
        </div>
      </div>
      <div 
      onClick={toggle}
      style={{
        ...styles.cardbutton,
        width: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        marginTop: '1rem'
      }}
    >
      Close Comments
    </div>
    </div>
  );
};

export default Comments;