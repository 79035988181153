import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Game from '../components/game.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useAppState } from '../AppStateContext';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const UnlockedGames = () => {
  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState({});
  const { isAuthenticated, getWithExpiry, setWithExpiry, userProfile } = useAppState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const profileID = userProfile?.id || null;
      
      // Create cache key
      const cacheKey = `unlocked_fixtures_${profileID}`;
      
      // Check cache first
      const cachedData = getWithExpiry(cacheKey);
      if (cachedData) {
        setGroupedData(cachedData);
        setLoading(false);
        console.log("Cache hit");
        return;
      }

      console.log("Cache miss");
      try {
        const url = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('authToken');
        const headers = token ? { Authorization: `Token ${token}` } : {};
        
        const { data: response } = await axios.get(`${url}/getUnlockedFixtures/`, {
          headers: headers
        });

        // Group fixtures by league and game time
        const grouped = response.reduce((acc, fixture) => {
          const league = fixture.league;
          const gameTime = formatTime(fixture.game_time);
          
          if (!acc[league]) {
            acc[league] = {};
          }
          if (!acc[league][gameTime]) {
            acc[league][gameTime] = [];
          }
          acc[league][gameTime].push(fixture);
          return acc;
        }, {});

        // Sort fixtures within each group by absolute value of home_spread
        Object.keys(grouped).forEach(league => {
          Object.keys(grouped[league]).forEach(time => {
            grouped[league][time].sort((a, b) => {
              const spreadA = Math.abs(parseFloat(a.home_spread));
              const spreadB = Math.abs(parseFloat(b.home_spread));
              return spreadA - spreadB;
            });
          });
        });

        // Sort the game times within each league
        const sortedGroupedData = {};
        Object.keys(grouped).forEach(league => {
          sortedGroupedData[league] = Object.keys(grouped[league])
            .sort((a, b) => new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b))
            .reduce((acc, key) => {
              acc[key] = grouped[league][key];
              return acc;
            }, {});
        });

        setGroupedData(sortedGroupedData);
        console.log("Got response at " + new Date().toLocaleTimeString());
        // Store in cache
        setWithExpiry(cacheKey, sortedGroupedData);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [getWithExpiry, setWithExpiry, userProfile]);

  return (
    <Container>
      <Col>
        {loading ? (
          <p>Loading...</p>
        ) : (
          Object.entries(groupedData).map(([league, timesData]) => (
            <React.Fragment key={league}>
              <h2 style={{ color: 'white', marginTop: '30px' }}>{league}</h2>
              {Object.entries(timesData).map(([gameTime, fixtures]) => (
                <React.Fragment key={`${league}-${gameTime}`}>
                  <h3 style={{ color: 'white', marginTop: '20px' }}>{gameTime}</h3>
                  <hr style={{ borderTop: '2px solid white', margin: '10px 0' }} />
                  {fixtures.map((fixture) => (
                    <Game
                      key={fixture.id}
                      fixture={fixture}
                      isAuthenticated={isAuthenticated}
                    />
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))
        )}
      </Col>
    </Container>
  );
};

export default UnlockedGames;