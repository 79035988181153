// Path: Bet/frontend/src/components/Cards/suggestions.js

import React from 'react';
import Card from 'react-bootstrap/Card';
import { styles } from '../../styles/styles.js'; // Adjust the path if needed

const Suggestions = ({ fixture, userTier }) => {
  // Only display if userTier is 'Pro'
  if (userTier !== 'Pro') {
    return null;
  }

  // Check if fixture.suggestions exists and is not empty
  if (!fixture.suggestions || fixture.suggestions.length === 0) {
    return null;
  }

  // Filter out suggestions missing 'text' or 'score'
  const validSuggestions = fixture.suggestions.filter(
    (suggestion) => suggestion.text && suggestion.score !== undefined
  );

  if (validSuggestions.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <h5 style={{ marginBottom: '15px' }}>Suggestions</h5>
      {validSuggestions.map((suggestion, index) => (
        <Card key={index} style={{ marginBottom: '10px', backgroundColor: 'black', color: 'white', borderColor: 'white', borderWidth: 1, borderStyle: 'solid', padding: 20 }}>
          <Card.Body>
            <Card.Text style={styles.analysis}>
              {suggestion.text}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small style={{ color: 'white' }}>Score: {suggestion.score.toFixed(2)}</small>
          </Card.Footer>
        </Card>
      ))}
    </div>
  );
};

export default Suggestions;