import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";
import { useAppState } from "../../AppStateContext.js";
import * as motion from "motion/react-client";


function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL" || fixture.league === "NHL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

function calculateWinPercentage(wins, draws, total) {
  return ((wins + draws * 0.5) / total) * 100;
}

const calculateStars = (ev1, ev2) => {
  if (isNaN(ev1) || isNaN(ev2)) return 0;
  const difference = ev1 - ev2;
  if (difference <= 0) return 0;
  if (difference > 0 && difference <= 10) return 1;
  if (difference > 10 && difference <= 30) return 2;
  return 3;
};

function generateAnalysis(fixture) {
  const insights = [];

  // Calculate key percentages for recent games
  const home3Games = (fixture.recent.home_spread_3 / 3) * 100;
  const away3Games = (fixture.recent.away_spread_3 / 3) * 100;
  const home5Games = (fixture.recent.home_spread_5 / 5) * 100;
  const away5Games = (fixture.recent.away_spread_5 / 5) * 100;
  const home10Games = (fixture.recent.home_spread_10 / 10) * 100;
  const away10Games = (fixture.recent.away_spread_10 / 10) * 100;
  
  // Season percentages
  const homeSeason = (fixture.stats.home_spread / fixture.stats.count_home) * 100;
  const awaySeason = (fixture.stats.away_spread / fixture.stats.count_away) * 100;
  
  // Home/Away percentages
  const homeGames = calculateWinPercentage(
    fixture.stats.home_home_spreads_covered,
    0,
    fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered
  );
  const awayGames = calculateWinPercentage(
    fixture.stats.away_away_spreads_covered,
    0,
    fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered
  );

  // Recent form analysis - last 3 games
  const recent3Diff = home3Games - away3Games;
  if (Math.abs(recent3Diff) > 30) {
    insights.push(`${recent3Diff > 0 ? fixture.home_team : fixture.away_team} has been covering significantly more in their last 3 games`);
  } else if (Math.abs(recent3Diff) > 10) {
    insights.push(`${recent3Diff > 0 ? fixture.home_team : fixture.away_team} has been covering more in their last 3 games`);
  }
  else {
    insights.push(`Both teams have been even ATS in their last 3 games`);
  }

  // Recent form analysis - last 5 games
  const recent5Diff = home5Games - away5Games;
  if (Math.abs(recent5Diff) > 30) {
    insights.push(`${recent5Diff > 0 ? fixture.home_team : fixture.away_team} has been covering significantly more in their last 5 games`);
  } else if (Math.abs(recent5Diff) > 10) {
    insights.push(`${recent5Diff > 0 ? fixture.home_team : fixture.away_team} has been covering more in their last 5 games`);
  }
  else {
    insights.push(`Both teams have been even ATS in their last 5 games`);
  }

  // Recent form analysis - last 10 games
  const recent10Diff = home10Games - away10Games;
  if (Math.abs(recent10Diff) > 30) {
    insights.push(`${recent10Diff > 0 ? fixture.home_team : fixture.away_team} has been covering significantly more in their last 10 games`);
  } else if (Math.abs(recent10Diff) > 10) {
    insights.push(`${recent10Diff > 0 ? fixture.home_team : fixture.away_team} has been covering more in their last 10 games`);
  }
  else {
    insights.push(`Both teams have been even ATS in their last 10 games`);
  }

  // Season performance analysis
  const seasonDiff = homeSeason - awaySeason;
  if (Math.abs(seasonDiff) > 30) {
    insights.push(`${seasonDiff > 0 ? fixture.home_team : fixture.away_team} has covered significantly more this season`);
  } else if (Math.abs(seasonDiff) > 10) {
    insights.push(`${seasonDiff > 0 ? fixture.home_team : fixture.away_team} has covered more overall this season`);
  }
  else {
    insights.push(`Both teams have been even ATS this season`);
  }

  // Home/Away analysis
  const homeAwayDiff = homeGames - awayGames;
  if (Math.abs(homeAwayDiff) > 30) {
    insights.push(`${homeAwayDiff > 0 ? fixture.home_team : fixture.away_team} has been significantly more at covering spreads in home/away games`);
  } else if (Math.abs(homeAwayDiff) > 10) {
    insights.push(`${homeAwayDiff > 0 ? fixture.home_team : fixture.away_team} has been more at covering spreads in home/away games`);
  }

  return insights;
}

function Spreads({ fixture }) {
  const [displayMode, setDisplayMode] = useState(2); // 0: Win-Loss, 1: Percentages, 2: Stars (default)
  const { isTutorialMode } = useAppState();

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  const AnimatedCell = ({ children }) => (
    <motion.div
      key={displayMode}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 0.5,
        scale: { type: "spring", damping: 10, stiffness: 100, bounce: 0.5 },
      }}>
      {children}
    </motion.div>
  );

  if (fixture.recent) {
    const insights = generateAnalysis(fixture);

    return (
      <>
        <Table bordered className="main">
          <tbody>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Spreads
            </td>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}></td>
          </tr>
          <tr>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}></td>
            <td className="Cells" style={styles.column_1}>{fixture.home_team}</td>
            <td className="Cells" style={styles.column_1}>{fixture.away_team}</td>

          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Last 3
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.home_spread_3} - ${3 - fixture.recent.home_spread_3}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_3 / 3) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_3 / 3) * 100,
                    (fixture.recent.away_spread_3 / 3) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.away_spread_3} - ${3 - fixture.recent.away_spread_3}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_3 / 3) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_3 / 3) * 100,
                    (fixture.recent.home_spread_3 / 3) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
          </tr>

          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Last 5
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.home_spread_5} - ${5 - fixture.recent.home_spread_5}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_5 / 5) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_5 / 5) * 100,
                    (fixture.recent.away_spread_5 / 5) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.away_spread_5} - ${5 - fixture.recent.away_spread_5}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_5 / 5) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_5 / 5) * 100,
                    (fixture.recent.home_spread_5 / 5) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
          </tr>

          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Last 10
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.home_spread_10} - ${10 - fixture.recent.home_spread_10}`
                : displayMode === 1
                ? `${((fixture.recent.home_spread_10 / 10) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.home_spread_10 / 10) * 100,
                    (fixture.recent.away_spread_10 / 10) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.recent.away_spread_10} - ${10 - fixture.recent.away_spread_10}`
                : displayMode === 1
                ? `${((fixture.recent.away_spread_10 / 10) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.recent.away_spread_10 / 10) * 100,
                    (fixture.recent.home_spread_10 / 10) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Season
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.stats.home_spread} - ${fixture.stats.count_home - fixture.stats.home_spread}`
                : displayMode === 1
                ? `${((fixture.stats.home_spread / fixture.stats.count_home) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.stats.home_spread / fixture.stats.count_home) * 100,
                    (fixture.stats.away_spread / fixture.stats.count_away) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.stats.away_spread} - ${fixture.stats.count_away - fixture.stats.away_spread}`
                : displayMode === 1
                ? `${((fixture.stats.away_spread / fixture.stats.count_away) * 100).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    (fixture.stats.away_spread / fixture.stats.count_away) * 100,
                    (fixture.stats.home_spread / fixture.stats.count_home) * 100
                  )} />
              }
              </AnimatedCell>
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                Last Season
              </td>
              <td className="Cells" style={styles.Cells}>
                <AnimatedCell>
                {displayMode === 0
                  ? `${fixture.stats.home_spread_last} - ${fixture.stats.count_home_last - fixture.stats.home_spread_last}`
                  : displayMode === 1
                  ? `${((fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      (fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100,
                      (fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100
                    )} />
                }
                </AnimatedCell>
              </td>
              <td className="Cells" style={styles.Cells}>
                <AnimatedCell>
                {displayMode === 0
                  ? `${fixture.stats.away_spread_last} - ${fixture.stats.count_away_last - fixture.stats.away_spread_last}`
                  : displayMode === 1
                  ? `${((fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      (fixture.stats.away_spread_last / fixture.stats.count_away_last) * 100,
                      (fixture.stats.home_spread_last / fixture.stats.count_home_last) * 100
                    )} />
                }
                </AnimatedCell>
              </td>
            </tr>
          )}
          <tr style={{...styles.Cells, borderTop: '0', borderBottom: '0'}} >
            <td className="Cells" style={{...styles.Cells, borderTop: '0', borderBottom: '0'}} colSpan="3">&nbsp;</td>
          </tr>
          <tr style={{...styles.Cells, borderTop: '0', borderBottom: '0'}} >
            <td className="Cells" style={{...styles.Cells, borderTop: '0', borderBottom: '0'}} colSpan="3">&nbsp;</td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Home
            </td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.stats.home_home_spreads_covered} - ${fixture.stats.home_home_spreads_not_covered}`
                : displayMode === 1
                ? `${calculateWinPercentage(
                    fixture.stats.home_home_spreads_covered,
                    0,
                    fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered
                  ).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.home_home_spreads_covered, 0, fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered),
                    calculateWinPercentage(fixture.stats.away_away_spreads_covered, 0, fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered)
                  )} />
              }
              </AnimatedCell>
            </td>
            <td className="Cells" style={styles.Cells}></td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
              Away
            </td>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}>
              <AnimatedCell>
              {displayMode === 0
                ? `${fixture.stats.away_away_spreads_covered} - ${fixture.stats.away_away_spreads_not_covered}`
                : displayMode === 1
                ? `${calculateWinPercentage(
                    fixture.stats.away_away_spreads_covered,
                    0,
                    fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered
                  ).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.away_away_spreads_covered, 0, fixture.stats.away_away_spreads_covered + fixture.stats.away_away_spreads_not_covered),
                    calculateWinPercentage(fixture.stats.home_home_spreads_covered, 0, fixture.stats.home_home_spreads_covered + fixture.stats.home_home_spreads_not_covered)
                  )} />
              }
              </AnimatedCell>
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Home Last Season
                </td>
                <td className="Cells" style={styles.Cells}>
                  <AnimatedCell>
                  {displayMode === 0
                    ? `${fixture.stats.home_home_spreads_covered_last} - ${fixture.stats.home_home_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.home_home_spreads_covered_last,
                        0,
                        fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.home_home_spreads_covered_last, 0, fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.away_home_spreads_covered_last, 0, fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last)
                      )} />
                  }
                  </AnimatedCell>
                </td>
                <td className="Cells" style={styles.Cells}>
                  <AnimatedCell>
                  {displayMode === 0
                    ? `${fixture.stats.away_home_spreads_covered_last} - ${fixture.stats.away_home_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.away_home_spreads_covered_last,
                        0,
                        fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.away_home_spreads_covered_last, 0, fixture.stats.away_home_spreads_covered_last + fixture.stats.away_home_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.home_home_spreads_covered_last, 0, fixture.stats.home_home_spreads_covered_last + fixture.stats.home_home_spreads_not_covered_last)
                      )} />
                  }
                  </AnimatedCell>
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Away Last Season
                </td>
                <td className="Cells" style={styles.Cells}>
                  <AnimatedCell>
                  {displayMode === 0
                    ? `${fixture.stats.home_away_spreads_covered_last} - ${fixture.stats.home_away_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.home_away_spreads_covered_last,
                        0,
                        fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.home_away_spreads_covered_last, 0, fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.away_away_spreads_covered_last, 0, fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last)
                      )} />
                  }
                  </AnimatedCell>
                </td>
                <td className="Cells" style={styles.Cells}>
                  <AnimatedCell>
                  {displayMode === 0
                    ? `${fixture.stats.away_away_spreads_covered_last} - ${fixture.stats.away_away_spreads_not_covered_last}`
                    : displayMode === 1
                    ? `${calculateWinPercentage(
                        fixture.stats.away_away_spreads_covered_last,
                        0,
                        fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last
                      ).toFixed(1)}%`
                    : <StarRating rating={calculateStars(
                        calculateWinPercentage(fixture.stats.away_away_spreads_covered_last, 0, fixture.stats.away_away_spreads_covered_last + fixture.stats.away_away_spreads_not_covered_last),
                        calculateWinPercentage(fixture.stats.home_away_spreads_covered_last, 0, fixture.stats.home_away_spreads_covered_last + fixture.stats.home_away_spreads_not_covered_last)
                      )} />
                  }
                  </AnimatedCell>
                </td>
              </tr>
            </>
          )}
        </tbody>
        </Table>

        <div style={{ height: '10vh' }}></div>

        <div style={{ marginTop: '20px' }}>
          {insights.length > 0 && (
            <ul style={{ 
              listStyleType: 'none', 
              padding: 0,
              margin: 0 
            }}>
              {insights.map((insight, i) => (
                <li key={i} style={styles.analysis}>
                  • {insight}
                </li>
              ))}
            </ul>
          )}
        </div>

        {isTutorialMode && (
          <ul style={{ fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)', marginBottom: '20px', textAlign: 'left', listStyleType: 'none', paddingLeft: '0' }}>
            <li>• Tap anywhere in the first column to toggle between modes</li>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ Win-Loss mode - show the spread record</li>
              <li>◦ Percentage mode - show the spread cover percentage</li>
              <li>◦ Star mode - for quick evaluation of spread performance</li>
            </ul>
            <li>• Star ratings
              <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
                <li>◦ ⭐ – Slight advantage in covering spreads</li>
                <li>◦ ⭐⭐ – Moderate advantage in covering spreads</li>
                <li>◦ ⭐⭐⭐ – Strong advantage in covering spreads</li>
              </ul>
            </li>
            <li>• How to interpret the data</li>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ Compare recent spread coverage (last 3, 5, 10 games) to season-long performance</li>
              <li>◦ Pay attention to home/away spread coverage differences</li>
            </ul>
          </ul>
        )}
      </>
    );
  }
  return null;
}

export default Spreads;