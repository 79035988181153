// AppStateContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';


const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTutorialMode, setIsTutorialMode] = useState(() => {
    const savedMode = localStorage.getItem('tutorialMode');
    return savedMode === null ? true : JSON.parse(savedMode);
  });
  const [cache, setCache] = useState({});

  const [userProfile, setUserProfile] = useState(() => {
    const savedProfile = localStorage.getItem('userProfile');
    return savedProfile ? JSON.parse(savedProfile) : null;
  });
  const [userTier, setUserTier] = useState(() => {
    return localStorage.getItem('userTier') || 'Anon';
  });

  const login = async (token) => {
    localStorage.clear();
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
    await fetchUserProfile(); 
  };

  const logout = useCallback(() => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUserProfile(null);
    setUserTier('Anon');
    setCache({});
  }, [setIsAuthenticated, setUserProfile, setUserTier, setCache]);
  


  const fetchUserProfile = useCallback(async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;
  
    try {
      const url = process.env.REACT_APP_API_URL;
      const { data } = await axios.get(`${url}/profile/`, {
        headers: { Authorization: `Token ${token}` }
      });
      const tier = data.subscriptions?.tier || 'Free';
      localStorage.setItem('userTier', tier);
      localStorage.setItem('userProfile', JSON.stringify(data));
      console.log('User profile: ', data);
      console.log('User tier: ', tier);
      setUserProfile(data);
      setUserTier(tier);
    } catch (error) {
      console.error('Error fetching profile:', error);
      logout();
    }
  }, [logout]);
    

  // Probably want to implement some sort of expiry for the auth token
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Fetch user profile if authenticated
  useEffect(() => {
    if (isAuthenticated && !userProfile) {
      fetchUserProfile();
    }
  }, [isAuthenticated, userProfile, fetchUserProfile]);

  const toggleTutorialMode = () => {
    setIsTutorialMode(prev => {
      const newValue = !prev;
      localStorage.setItem('tutorialMode', JSON.stringify(newValue));
      return newValue;
    });
  };

  // Cache functions
  const setWithExpiry = (key, value) => {
    const item = {
      value,
      timestamp: new Date().getTime(),
      expiry: 1 * 60 * 60 * 1000 // 1 hour in milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
    setCache(prev => ({ ...prev, [key]: item }));
  };

  const getWithExpiry = (key) => {
    // First check memory cache
    if (cache[key]) {
      const now = new Date().getTime();
      console.log('Time diff:', now - cache[key].timestamp, 'ms');
      console.log('Expiry:', cache[key].expiry, 'ms');
  
      if (now - cache[key].timestamp <= cache[key].expiry) {
        return cache[key].value;
      }
    }

    // If not in memory, check localStorage
    const itemStr = localStorage.getItem(key);
    console.log('localStorage check:', itemStr);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    
    if (now - item.timestamp > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    
    // Update memory cache
    setCache(prev => ({ ...prev, [key]: item }));
    return item.value;
  };


  const value = {
    isAuthenticated,
    login,
    logout,
    isTutorialMode,
    toggleTutorialMode,
    setWithExpiry,
    getWithExpiry,
    userProfile,
    userTier,
    setUserTier,
    fetchUserProfile,
    setUserProfile
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider');
  }
  return context;
};