import React, { useState, useEffect, useRef } from "react";
import Recents from "./Cards/recents.js";
import HeadtoHead from "./Cards/headtohead.js";
import Score from "./Cards/score.js";
import Versus from "./Cards/versus.js";
import Totals from "./Cards/totals.js";
import PointDiff from "./Cards/pointdiff.js";
import Overs from "./Cards/overs.js";
import Wins from "./Cards/wins.js";
import Spreads from "./Cards/spreads.js";
import Projections from "./Cards/projections.js";
import Card from "react-bootstrap/Card";
import Comments from "./Cards/comments.js";
import Unlock from "./Cards/unlock.js";
import OddsTable from "./OddsTable.js";
import Suggestions from "./Cards/suggestions.js";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";
import { useLocation } from "react-router-dom";
import createScrollSnap from "scroll-snap";
import { useNavigate } from 'react-router-dom';
import * as motion from "motion/react-client";
import axios from 'axios';
import { Adsense } from '@ctrl/react-adsense';


const CardButton = ({ title, onClick, index = 0 }) => (
  <motion.div
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0}}
    transition={{
      duration: 0.5,
      delay: index * 0.15, // This creates the stagger effect
      scale: { type: "spring", damping: 10, stiffness: 100, bounce: 0.5 }
    }}
  >
    <Card 
      onClick={onClick}
      className="mb-3 game-card"
      style={{...styles.cardbutton, width: '100%'}}
    >
      <Card.Body>
        <Card.Title style={{ color: 'white', margin: 0, fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)' }}>{title}</Card.Title>
      </Card.Body>
    </Card>
  </motion.div>
);

const Games = ({ isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fixture] = useState(location.state?.fixture);
  const [isDataVisible, setDataVisible] = useState(false);
  const [isRecentsHomeVisible, setRecentsHomeVisible] = useState(false);
  const [isRecentsAwayVisible, setRecentsAwayVisible] = useState(false);
  const [isHeadtoHeadVisible, setHeadtoHeadVisible] = useState(false);
  const [isPowerRatingsVisible, setPowerRatingsVisible] = useState(false);
  const [isButtonListVisible, setButtonListVisible] = useState(true);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [isUnlockVisible, setIsUnlockVisible] = useState(false);



  const { isTutorialMode, userTier, userProfile, toggleTutorialMode } = useAppState();
  const containerRef = useRef(null);

  const isAuthenticatedAndPro = isAuthenticated && userTier === 'Pro';
  const isAuthenticatedAndFree = isAuthenticated && userTier === 'Free';

  const unlockedFixtures = userProfile && (userProfile.unlocked_fixtures || null)

  const isFixtureUnlocked = unlockedFixtures ? unlockedFixtures.includes(fixture.id) : false;


  const toggleDataVisibility = () => {
    setDataVisible(!isDataVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleRecentsHomeVisibility = () => {
    setRecentsHomeVisible(!isRecentsHomeVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleRecentsAwayVisibility = () => {
    setRecentsAwayVisible(!isRecentsAwayVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleHeadtoHeadVisibility = () => {
    setHeadtoHeadVisible(!isHeadtoHeadVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const togglePowerRatingsVisibility = () => {
    setPowerRatingsVisible(!isPowerRatingsVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const resetAll = () => {
    console.log('Resetting all');
    setDataVisible(false);
    setRecentsHomeVisible(false);
    setRecentsAwayVisible(false);
    setHeadtoHeadVisible(false);
    setPowerRatingsVisible(false);
    setButtonListVisible(true);
  };

  const handleBackToGames = () => {
    const gameDate = new Date(fixture.game_time);
    navigate(`/${fixture.league.toLowerCase()}`, {
      state: { selectedDate: gameDate }
    });
  };

  useEffect(() => {
    const getCommentCount = async () => {
      if (!fixture?.id) return;
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/comments/count_comments/`, {
          params: { fixture: fixture.id }
        });
        setCommentCount(response.data.count);
      } catch (error) {
        console.error('Error fetching comment count:', error);
      }
    };

    getCommentCount();
}, [fixture?.id]); 

  
  
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();
      return () => unbind();
    }
  }, []);

  return (
    <div>
      <Card className="main" style={styles.main}>
        <Card.Body>
          <Card.Title>
            <b>
              <Versus fixture={fixture} /> at {convertUTCDateToLocalDateTime(fixture.game_time)}
            </b>
          </Card.Title>
          <OddsTable fixture={fixture} onClick={resetAll} />

          {isButtonListVisible && userTier !== 'Pro' && <Adsense client="ca-pub-6287941507214213" slot="9261893876"/>}

          {isButtonListVisible && (<Suggestions fixture={fixture} userTier={userTier} />)}

            {isButtonListVisible && (
              <CardButton 
                title="Projections"
                onClick={togglePowerRatingsVisibility}
                index={0}
              />
            )}
            {isPowerRatingsVisible && isAuthenticatedAndPro && (
              <Projections fixture={fixture} onClose={togglePowerRatingsVisibility} />
            )}
            {isPowerRatingsVisible && isAuthenticatedAndFree && (
              <>
                {isFixtureUnlocked ? (
                  <Projections fixture={fixture} onClose={togglePowerRatingsVisibility} />
                ) : (
                  <Unlock
                    fixture={fixture}
                    onClose={togglePowerRatingsVisibility}
                  />
                )}
              </>
            )}
            {isButtonListVisible && !isDataVisible && (
              <CardButton 
                title="Stats"
                onClick={toggleDataVisibility}
                index={1}
              />
            )}

            {isDataVisible && (
              <div ref={containerRef} style={{
                overflowY: "scroll",
                height: "100vh",
                scrollSnapType: "y mandatory",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}>
                    {isTutorialMode && (
                      <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start", position: "relative", padding: 0, margin: 0 }}>
                        <ul
                          style={{
                            fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                            marginBottom: "20px",
                            textAlign: "left", 
                            listStyleType: "none",
                            paddingLeft: "0",
                            width: "100%",
                          }}>
                          <li>Swipe up to navigate</li>
                        </ul>

                        <div style={{
                          position: "absolute", 
                          bottom: "25vh",
                          left: "50%",
                          transform: "translateX(-50%)",
                          margin: 0,
                          padding: 0,
                        }}>
                          <motion.svg 
                            width="80" 
                            height="80" 
                            viewBox="0 0 24 24"
                            animate={{ 
                              y: [-80, 0, -80],
                            }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}>
                            <path 
                              d="M12 3l8 8h-6v10h-4v-10h-6z"
                              fill="currentColor"
                            />
                          </motion.svg>
                        </div>
                      </section>
                    )}
                    {isTutorialMode && (
                      <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start", position: "relative", padding: 0, margin: 0 }}>
                      <div style={{
                        marginBottom: "20px",
                        textAlign: "center",
                        padding: "20px"
                      }}>
                    <ul
                      style={{
                        fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                        marginBottom: "20px",
                        textAlign: "left",
                        listStyleType: "none",
                        paddingLeft: "0",
                      }}>
                      <li>• Tap anywhere in the first column to toggle between modes</li>
                      <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                        <li>◦ Win-Loss mode - show the team's win-loss record</li>
                        <li>◦ Percentage mode - show the win percentage</li>
                        <li>◦ Star mode - for quick evaluation of team performance</li>
                      </ul>
                      <li>
                        • Star ratings
                        <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                          <li>◦ ⭐ – Slight advantage</li>
                          <li>◦ ⭐⭐ – Moderate advantage</li>
                          <li>◦ ⭐⭐⭐ – Strong advantage</li>
                        </ul>
                      </li>
                      <li>• How to interpret the data</li>
                      <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                        <li>◦ Compare recent performance (last 3, 5, 10 games) to season-long performance</li>
                        <li>◦ Pay attention to home/away performance differences</li>
                      </ul>
                    </ul>
                    </div>
                    </section>
                  )}
                  {isTutorialMode && (
                    <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start", position: "relative", padding: 0, margin: 0 }}>
                      <div style={{
                        marginBottom: "20px",
                        textAlign: "center",
                        padding: "20px"
                      }}>
                        <p style={styles.analysis}>
                          Done with the tutorial? Tap the button below to disable. You can always re-enable in the sidebar menu or in the profile page.

                          We recommend leaving this on for now since there are additional explanations and tips for each specific section later on.
                        </p>
                      </div>
                      <Card 
                        className="cardbutton" 
                        style={{...styles.cardbutton, width: '100%', marginBottom: "20px"}} 
                        onClick={toggleTutorialMode}
                      >
                        <Card.Text>Disable Tutorial Mode</Card.Text>
                      </Card>
                    </section>
                  )}
                  <section style={{ ...styles.sectionStyle, minHeight: "110vh" }}>
                    <Wins fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Spreads fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <PointDiff fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Totals fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    <Overs fixture={fixture} />
                  </section>
                  <section style={styles.sectionStyle}>
                    {isTutorialMode && (
                      <ul style={{
                        fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                        textAlign: "left",
                        listStyleType: "none",
                        paddingLeft: "0",
                      }}>
                        <li>• You've reached the end of the main stats section. Continue scrolling to see recent games, head-to-head, projections, and comments.</li>
                        <li>• More info:
                          <ul style={{
                            paddingLeft: "20px",
                            listStyleType: "none",
                          }}>
                            <li>◦ The recent games section shows the last 10 games for each team with the elo ratings before each game</li>
                            <li>◦ The head-to-head section shows up to the last 10 matches between the two current</li>
                            <li>◦ The projections section shows our models' recommended plays and win probabilities. Check this after making your decisions to see how it lines up with our own picks. Or start with the recommended picks and work backwards. This is how we do it in our office</li>
                          </ul>
                        </li>
                      </ul>
                    )}
                    <Card className="cardbutton" style={{...styles.cardbutton, width: '100%'}} onClick={toggleDataVisibility}>
                      <Card.Text>Close</Card.Text>
                    </Card>
                  </section>
                </div>
              </div>
            )}

            {isButtonListVisible && (
              <CardButton 
                title={`${fixture.home_team} Recent Games`}
                onClick={toggleRecentsHomeVisibility}
                index={2}
              />
            )}

            {isRecentsHomeVisible && (
              <>
                <Recents name={fixture.home_team} date={fixture.game_time} league={fixture.league} />
                <CardButton 
                  title="Close Recent Games"
                  onClick={toggleRecentsHomeVisibility}
                />
              </>
            )}

            {isButtonListVisible && (
                          <CardButton 
                            title={`${fixture.away_team} Recent Games`}
                            onClick={toggleRecentsAwayVisibility}
                            index={3}
                          />
                        )}

            {isRecentsAwayVisible && (
              <>
                <Recents name={fixture.away_team} date={fixture.game_time} league={fixture.league} />
                <CardButton 
                  title="Close Recent Games"
                  onClick={toggleRecentsAwayVisibility}
                />
              </>
            )}

            {isButtonListVisible && (
              <CardButton 
                title="Head to Head"
                onClick={toggleHeadtoHeadVisibility}
                index={4}
              />
            )}

            {isHeadtoHeadVisible && (
              <HeadtoHead team1={fixture.home_team} team2={fixture.away_team} game={fixture} onClose={toggleHeadtoHeadVisibility} />
            )}

          {isButtonListVisible && (
            <CardButton 
              title={`Comments (${commentCount})`}
              onClick={() => {
                if (isAuthenticatedAndPro || (isAuthenticatedAndFree && isFixtureUnlocked)) {
                  setIsCommentsOpen(true);
                } else if (isAuthenticatedAndFree && !isFixtureUnlocked) {
                  setIsUnlockVisible(true);
                }
              }}
              index={5}
            />
          )}

          <CardButton 
            title="Back"
            onClick={handleBackToGames}
            index={6}
          />
          
          <Spacer size={32} />
          
          {isUnlockVisible && isAuthenticatedAndFree && !isFixtureUnlocked && (
            <Unlock
              fixture={fixture}
              onClose={() => setIsUnlockVisible(false)}
            />
          )}
          
            {isButtonListVisible && 
              <Comments 
                fixture={fixture} 
                isAuthenticated={isAuthenticated}
                isOpen={isCommentsOpen}
                toggle={() => setIsCommentsOpen(!isCommentsOpen)}
              />
            }
          <Score fixture={fixture} />
          <p>id: {fixture.id}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

const Spacer = ({ size }) => <div style={{ height: size }} />;

export default Games;