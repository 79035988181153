import React from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../styles/styles.js";
import * as motion from "motion/react-client";

const OddsTable = ({ fixture, selectedTeam, onClick, mode }) => {
    const getHomeSpreadStyle = () => {
      if (mode === "Recents") {
        let teamPoints, opponentPoints, spread;
        if (selectedTeam === fixture.home_team) {
            teamPoints = fixture.home_points;
            opponentPoints = fixture.away_points;
            spread = fixture.home_spread;
            
            if (teamPoints + spread >= opponentPoints) {
              return styles.Suggested;
            }
        }
      }
      if (mode === "HeadtoHead") {
        if (fixture.home_points + fixture.home_spread >= fixture.away_points) {
          return styles.result;
        }
      }
      return styles.Odds;
    };

  const getHomeMLStyle = () => {
    if (mode === "Recents") {
      let teamPoints, opponentPoints;
      if (selectedTeam === fixture.home_team) {
        teamPoints = fixture.home_points;
        opponentPoints = fixture.away_points;
        
        if (teamPoints >= opponentPoints) {
          return styles.Suggested;
        }
      }
    }

    if (mode === "HeadtoHead") {
      if (fixture.home_points >= fixture.away_points) {
        return styles.result;
      }
    }

    return styles.Odds;
  };

  const getOverStyle = () => {
    if (mode === "Recents") {
      if (fixture.home_points + fixture.away_points >= fixture.over) {
        return styles.Suggested;
      }
    }
    if (mode === "HeadtoHead") {
      if (fixture.home_points + fixture.away_points >= fixture.over) {
        return styles.result;
      }
    }
    return styles.Odds;
  };

  const getAwaySpreadStyle = () => {
    if (mode === "Recents") {
      let teamPoints, opponentPoints, spread;
      if (selectedTeam === fixture.away_team) {
        teamPoints = fixture.away_points;
        opponentPoints = fixture.home_points;
        spread = fixture.away_spread;
        
        if (teamPoints + spread >= opponentPoints) {
          return styles.Suggested;
        }
      }
    }
    if (mode === "HeadtoHead") {
      if (fixture.away_points + fixture.away_spread >= fixture.home_points) {
        return styles.result;
      }
    }
    return styles.Odds;
  };

  const getAwayMLStyle = () => {
    if (mode === "Recents") {
      let teamPoints, opponentPoints;
      if (selectedTeam === fixture.away_team) {
        teamPoints = fixture.away_points;
        opponentPoints = fixture.home_points;
        
        if (teamPoints >= opponentPoints) {
          return styles.Suggested;
        }
      }
    }
    if (mode === "HeadtoHead") {
      if (fixture.away_points >= fixture.home_points) {
        return styles.result;
      }
    }
    return styles.Odds;
  };

  const getUnderStyle = () => {
    if (mode === "Recents") {
      if (fixture.winner && fixture.home_points + fixture.away_points <= fixture.under) {
        return styles.Suggested;
      }
    }
    if (mode === "HeadtoHead") {
      if (fixture.home_points + fixture.away_points <= fixture.under) {
        return styles.result;
      }
    }
    return styles.Odds;
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0}}
      transition={{
        duration: 0.5,
        scale: { type: "spring", damping: 10, stiffness: 100, bounce: 0.5 }
      }}
    >
      <Table className="main" bordered onClick={onClick}>
        <tbody>
          <tr>
            <td className="Odds" style={styles.Odds}> Home </td>
            <td className="Odds" style={styles.Odds}> {fixture.home_team} </td>
            <td className="Odds" style={getHomeSpreadStyle()}>
              {fixture.home_spread} ({fixture.home_spread_price})
            </td>
            <td className="Odds" style={getHomeMLStyle()}>
              {fixture.home_moneyline}
            </td>
            <td className="Odds" style={getOverStyle()}>
              {fixture.over} ({fixture.over_price})
            </td>
          </tr>
          <tr>
            <td className="Odds" style={styles.Odds}> Away </td>
            <td className="Odds" style={styles.Odds}> {fixture.away_team} </td>
            <td className="Odds" style={getAwaySpreadStyle()}>
              {fixture.away_spread} ({fixture.away_spread_price})
            </td>
            <td className="Odds" style={getAwayMLStyle()}>
              {fixture.away_moneyline}
            </td>
            <td className="Odds" style={getUnderStyle()}>
              {fixture.under} ({fixture.under_price})
            </td>
          </tr>
        </tbody>
      </Table>
    </motion.div>
  );
};

export default OddsTable;