import React, { useState, useRef, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";
import { useAppState } from "../../AppStateContext.js";
import createScrollSnap from "scroll-snap";
import Card from "react-bootstrap/Card";
import * as motion from "motion/react-client";

function oddsToProb(odds) {
  let impliedProbability;
  if (odds > 0) {
    impliedProbability = 100 / (odds + 100);
  } else {
    impliedProbability = Math.abs(odds) / (Math.abs(odds) + 100);
  }
  return (impliedProbability * 100).toFixed(2);
}

function getHomeSpreadProb(fixture) {
  if (fixture.home_spread) {
    return oddsToProb(fixture.home_spread_price);
  }
}

function getAwaySpreadProb(fixture) {
  if (fixture.away_spread) {
    return oddsToProb(fixture.away_spread_price);
  }
}

function getHomeMoneylineProb(fixture) {
  if (fixture.home_moneyline) {
    return oddsToProb(fixture.home_moneyline);
  }
  return 0;
}

function getAwayMoneylineProb(fixture) {
  if (fixture.away_moneyline) {
    return oddsToProb(fixture.away_moneyline);
  }
  return 0;
}

function calculateVig(prob1, prob2) {
  return ((parseFloat(prob1) + parseFloat(prob2) - 100) / 2).toFixed(2);
}

function calculateStars(ev1, ev2) {
  if (isNaN(ev1) || isNaN(ev2)) return 0;
  if (ev1 < 0 && ev2 < 0) {
    return Math.abs(ev1) < Math.abs(ev2) ? 1 : 0;
  } else if (ev1 > 0 && ev1 <= 3) {
    return 2;
  } else if (ev1 > 3) {
    return 3;
  }
  return 0;
}

function generateAnalysis(modelProbabilities, type, fixture, {
  homeEloWinProb,
  awayEloWinProb,
  homeMoneylineProb,
  awayMoneylineProb,
  homeEloSpreadProb,
  awayEloSpreadProb,
  homeSpreadProb,
  awaySpreadProb,
  homeEloRating,
  awayEloRating
}) {
  const insights = [];
  const moneyline_vig = calculateVig(homeMoneylineProb, awayMoneylineProb);
  const spread_vig = calculateVig(homeSpreadProb, awaySpreadProb);
  // const homeEloEV = (parseFloat(homeEloWinProb) - parseFloat(homeMoneylineProb)).toFixed(2);
  // const awayEloEV = (parseFloat(awayEloWinProb) - parseFloat(awayMoneylineProb)).toFixed(2);
  // const eloStarsHome = calculateStars(parseFloat(homeEloEV), parseFloat(awayEloEV));
  // const eloStarsAway = calculateStars(parseFloat(awayEloEV), parseFloat(homeEloEV));
  const model_A_Stars_Home_Win = calculateStars(parseFloat(modelProbabilities[0].homeWinEV), parseFloat(modelProbabilities[0].awayWinEV));
  const model_A_Stars_Away_Win = calculateStars(parseFloat(modelProbabilities[0].awayWinEV), parseFloat(modelProbabilities[0].homeWinEV));
  const model_A_EV_Home_Win = parseFloat(modelProbabilities[0].homeWinEV);
  const model_A_EV_Away_Win = parseFloat(modelProbabilities[0].awayWinEV);
  const model_A_Prob_Home_Win = parseFloat(modelProbabilities[0].homeWinProb);
  const model_A_Prob_Away_Win = parseFloat(modelProbabilities[0].awayWinProb);


  const model_A_Cover_Home = parseFloat(modelProbabilities[0].homeCoverProb);
  const model_A_Cover_Away = parseFloat(modelProbabilities[0].awayCoverProb);
  const model_A_Cover_Home_EV = parseFloat(modelProbabilities[0].homeCoverEV);
  const model_A_Cover_Away_EV = parseFloat(modelProbabilities[0].awayCoverEV);
  const model_A_Stars_Home_Cover = calculateStars(parseFloat(modelProbabilities[0].homeCoverEV), parseFloat(modelProbabilities[0].awayCoverEV));
  const model_A_Stars_Away_Cover = calculateStars(parseFloat(modelProbabilities[0].awayCoverEV), parseFloat(modelProbabilities[0].homeCoverEV));

  const model_A_Stars_Over = calculateStars(parseFloat(modelProbabilities[0].overEV), parseFloat(modelProbabilities[0].underEV));
  // const model_A_Stars_Under = calculateStars(parseFloat(modelProbabilities[0].underEV), parseFloat(modelProbabilities[0].overEV));
  // const model_A_EV_Over = parseFloat(modelProbabilities[0].overEV);
  // const model_A_EV_Under = parseFloat(modelProbabilities[0].underEV);
  const model_A_Prob_Over = parseFloat(modelProbabilities[0].overProb);
  const model_A_Prob_Under = parseFloat(modelProbabilities[0].underProb);

  const model_B_Stars_Home_Win = calculateStars(parseFloat(modelProbabilities[1].homeWinEV), parseFloat(modelProbabilities[1].awayWinEV));
  const model_B_Stars_Away_Win = calculateStars(parseFloat(modelProbabilities[1].awayWinEV), parseFloat(modelProbabilities[1].homeWinEV));
  // const model_B_EV_Home_Win = parseFloat(modelProbabilities[1].homeWinEV);
  const model_B_EV_Away_Win = parseFloat(modelProbabilities[1].awayWinEV);
  // const model_B_Prob_Home_Win = parseFloat(modelProbabilities[1].homeWinProb);
  // const model_B_Prob_Away_Win = parseFloat(modelProbabilities[1].awayWinProb);


  // const model_B_Cover_Home = parseFloat(modelProbabilities[1].homeCoverProb);
  const model_B_Cover_Away = parseFloat(modelProbabilities[1].awayCoverProb);
  // const model_B_Cover_Home_EV = parseFloat(modelProbabilities[1].homeCoverEV);
  const model_B_Cover_Away_EV = parseFloat(modelProbabilities[1].awayCoverEV);
  const model_B_Stars_Home_Cover = calculateStars(parseFloat(modelProbabilities[1].homeCoverEV), parseFloat(modelProbabilities[1].awayCoverEV));  
  const model_B_Stars_Away_Cover = calculateStars(parseFloat(modelProbabilities[1].awayCoverEV), parseFloat(modelProbabilities[1].homeCoverEV));

  const model_B_Stars_Over = calculateStars(parseFloat(modelProbabilities[1].overEV), parseFloat(modelProbabilities[1].underEV));
  const model_B_Stars_Under = calculateStars(parseFloat(modelProbabilities[1].underEV), parseFloat(modelProbabilities[1].overEV));
  // const model_B_EV_Over = parseFloat(modelProbabilities[1].overEV);
  // const model_B_EV_Under = parseFloat(modelProbabilities[1].underEV);
  const model_B_Prob_Over = parseFloat(modelProbabilities[1].overProb);
  const model_B_Prob_Under = parseFloat(modelProbabilities[1].underProb);

  if (type === "moneyline") {
    if (model_A_Stars_Home_Win >= 1 && model_B_Stars_Away_Win >= 1) {
      insights.push(`Our models are in disagreement.`)
      insights.push(`Based on Model A, our best one, ${fixture.home_team} has an expected value of +(` + model_A_EV_Home_Win.toFixed(1) +
        '%) and a win probability of ' + model_A_Prob_Home_Win.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.home_team} winning at ${fixture.home_moneyline} which is equivalent to ${homeMoneylineProb}% `)
      insights.push(`Based on Model B, ${fixture.away_team} has an expected value of +(` + model_B_EV_Away_Win.toFixed(1) +
        '%) and a win probability of ' + model_A_Prob_Away_Win.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.away_team} winning at ${fixture.away_moneyline} which is equivalent to ${awayMoneylineProb}% `)
    }
    else {
      if (model_A_Stars_Home_Win === 3 && model_B_Stars_Home_Win >= 1) {
        insights.push(`Our models strongly suggest ${fixture.home_team} is a better value`);
      }
      else if (model_A_Stars_Away_Win === 3 && model_B_Stars_Away_Win >= 1) {
        insights.push(`Our models strongly suggest ${fixture.away_team} is a better value`);
      }
      else if (model_A_Stars_Home_Win === 2 && model_B_Stars_Home_Win >= 1) {
        insights.push(`Our models suggest ${fixture.home_team} is a better value`);
      }
      else if (model_A_Stars_Away_Win === 2 && model_B_Stars_Away_Win >= 1) {
        insights.push(`Our models suggest ${fixture.away_team} is a better value`);
      }
      else if (model_A_Stars_Home_Win === 1 && model_B_Stars_Home_Win >= 1) {
        insights.push(`Our models are leaning towards ${fixture.home_team} being a better value`);
      }
      else if (model_A_Stars_Away_Win === 1 && model_B_Stars_Away_Win >= 1) {
        insights.push(`Our models are leaning towards ${fixture.away_team} being a better value`);
      }
      insights.push(`Based on Model A, our best one, ${fixture.home_team} has an expected value of +(` + model_A_EV_Home_Win.toFixed(1) +
        '%) and a win probability of ' + model_A_Prob_Home_Win.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.home_team} winning at ${fixture.home_moneyline} which is equivalent to ${homeMoneylineProb}% `)
      insights.push(`Based on Model A, our best one, ${fixture.away_team} has an expected value of +(` + model_A_EV_Away_Win.toFixed(1) +
        '%) and a win probability of ' + model_A_Prob_Away_Win.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.away_team} winning at ${fixture.away_moneyline} which is equivalent to ${awayMoneylineProb}% `)
    }
    insights.push(`These market prices include the vig of ${moneyline_vig}%, which you need to subtract to get the implied probability`)
  } 
  else if (type === "spread") {
    if (model_A_Stars_Home_Cover >= 1 && model_B_Stars_Away_Cover >= 1) {
      insights.push(`Our models are in disagreement.`)
      insights.push(`Based on Model A, our best one, ${fixture.home_team} has an expected value of +(` + model_A_Cover_Home_EV.toFixed(1) +
        '%) and a cover probability of ' + model_A_Cover_Home.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.home_team} covering at ${fixture.home_spread_price} which is equivalent to ${homeSpreadProb}% `)
      insights.push(`Based on Model B, ${fixture.away_team} has an expected value of +(` + model_B_Cover_Away_EV.toFixed(1) +
        '%) and a cover probability of ' + model_B_Cover_Away.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.away_team} covering at ${fixture.away_spread_price} which is equivalent to ${awaySpreadProb}% `)
    }
    else {
      if (model_A_Stars_Home_Cover === 3 && model_B_Stars_Home_Cover >= 1) {
        insights.push(`Our models strongly suggest ${fixture.home_team} is a better value against the spread`);
      }
      else if (model_A_Stars_Away_Cover === 3 && model_B_Stars_Away_Cover >= 1) {
        insights.push(`Our models strongly suggest ${fixture.away_team} is a better value against the spread`);
      }
      else if (model_A_Stars_Home_Cover === 2 && model_B_Stars_Home_Cover >= 1) {
        insights.push(`Our models suggest ${fixture.home_team} is a better value against the spread`);
      }
      else if (model_A_Stars_Away_Cover === 2 && model_B_Stars_Away_Cover >= 1) {
        insights.push(`Our models suggest ${fixture.away_team} is a better value against the spread`);
      }
      else if (model_A_Stars_Home_Cover === 1 && model_B_Stars_Home_Cover >= 1) {
        insights.push(`Our models are leaning towards ${fixture.home_team} being a better value against the spread`);
      }
      else if (model_A_Stars_Away_Cover === 1 && model_B_Stars_Away_Cover >= 1) {
        insights.push(`Our models are leaning towards ${fixture.away_team} being a better value against the spread`);
      }
      insights.push(`Based on Model A, our best one, ${fixture.home_team} has an expected value of +(` + model_A_Cover_Home_EV.toFixed(1) +
        '%) and a cover probability of ' + model_A_Cover_Home.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.home_team} covering at ${fixture.home_spread_price} which is equivalent to ${homeSpreadProb}% `)
      insights.push(`Based on Model A, our best one, ${fixture.away_team} has an expected value of +(` + model_A_Cover_Away_EV.toFixed(1) +
        '%) and a cover probability of ' + model_A_Cover_Away.toFixed(1) + '%');
      insights.push(`The market is pricing ${fixture.away_team} covering at ${fixture.away_spread_price} which is equivalent to ${awaySpreadProb}% `)
    }
    insights.push(`These market prices include the vig of ${spread_vig}%, which you need to subtract to get the implied probability`)
  } 
  else if (type === "over") {
    if (model_A_Stars_Over >= 1 && model_B_Stars_Under >= 1) {
      insights.push(`Our models are in disagreement.`)
    }
    else {
      if (model_A_Stars_Over === 3 && model_B_Stars_Over >= 1) {
        insights.push(`Our models strongly suggest the over is a better value`);
      }
    }
    insights.push(`Model A has the over at ${model_A_Prob_Over}% and the under at ${model_A_Prob_Under}%`)
    insights.push(`Model B has the over at ${model_B_Prob_Over}% and the under at ${model_B_Prob_Under}%`)
    insights.push(`The market is pricing the over at ${fixture.over_price} which is equivalent to ${oddsToProb(fixture.over_price)}% `)
    insights.push(`The market is pricing the under at ${fixture.under_price} which is equivalent to ${oddsToProb(fixture.under_price)}% `)
  } 
  else if (type === "elo") {
    const eloDiff = parseFloat(homeEloRating) - parseFloat(awayEloRating);
    insights.push(`${eloDiff > 0 ? fixture.home_team : fixture.away_team} has a  Elo rating advantage of ${Math.abs(eloDiff)} points.`);

  }

  return insights;
}

function Projections({ fixture, onClose }) {
  const [displayMode, setDisplayMode] = useState(2); // 2: Future State, 0: Probabilities, 1: EV
  const { isTutorialMode, toggleTutorialMode } = useAppState();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();
      return () => unbind();
    }
  }, []);

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  if (fixture.ratings && fixture.insights) {
    const modelTypes = ["NeuralNetClassifier", "HistGradientBoostingClassifier"];

    const modelAliases = {
      NeuralNetClassifier: "Model A",
      HistGradientBoostingClassifier: "Model B",
    };

    // Pre-calculate all values
    const homeEloWinProb = (fixture.ratings.home_elo_prob_ml * 100).toFixed(1);
    const awayEloWinProb = (fixture.ratings.away_elo_prob_ml * 100).toFixed(1);
    const homeMoneylineProb = getHomeMoneylineProb(fixture);
    const awayMoneylineProb = getAwayMoneylineProb(fixture);
    const homeEloSpreadProb = (fixture.ratings.home_elo_prob_spread * 100).toFixed(1);
    const awayEloSpreadProb = (fixture.ratings.away_elo_prob_spread * 100).toFixed(1);
    const homeSpreadProb = getHomeSpreadProb(fixture);
    const awaySpreadProb = getAwaySpreadProb(fixture);

    // New constants for vig calculation
    const moneylineVig = calculateVig(homeMoneylineProb, awayMoneylineProb);
    const spreadVig = calculateVig(homeSpreadProb, awaySpreadProb);

    // Calculate totals vig
    const overProb = oddsToProb(fixture.over_price);
    const underProb = oddsToProb(fixture.under_price);
    const totalsVig = calculateVig(overProb, underProb);

    const modelProbabilities = modelTypes.map((model) => {
      const homeWinProb = (fixture.insights[`home_win_prob_${model}`] * 100).toFixed(1);
      const awayWinProb = (fixture.insights[`away_win_prob_${model}`] * 100).toFixed(1);
      const homeCoverProb = (fixture.insights[`home_cover_prob_${model}`] * 100).toFixed(1);
      const awayCoverProb = (fixture.insights[`away_cover_prob_${model}`] * 100).toFixed(1);
      const overProb = (fixture.insights[`over_prob_${model}`] * 100).toFixed(1);
      const underProb = (fixture.insights[`under_prob_${model}`] * 100).toFixed(1);

      return {
        model: modelAliases[model] || model.toUpperCase(),
        homeWinProb,
        awayWinProb,
        homeCoverProb,
        awayCoverProb,
        overProb,
        underProb,
        homeWinEV: (parseFloat(homeWinProb) - parseFloat(homeMoneylineProb)).toFixed(2),
        awayWinEV: (parseFloat(awayWinProb) - parseFloat(awayMoneylineProb)).toFixed(2),
        homeCoverEV: (parseFloat(homeCoverProb) - parseFloat(homeSpreadProb)).toFixed(2),
        awayCoverEV: (parseFloat(awayCoverProb) - parseFloat(awaySpreadProb)).toFixed(2),
        overEV: (parseFloat(overProb) - parseFloat(oddsToProb(fixture.over_price))).toFixed(2),
        underEV: (parseFloat(underProb) - parseFloat(oddsToProb(fixture.under_price))).toFixed(2),
      };
    });

    const homeEloEV = (parseFloat(homeEloWinProb) - parseFloat(homeMoneylineProb)).toFixed(2);
    const awayEloEV = (parseFloat(awayEloWinProb) - parseFloat(awayMoneylineProb)).toFixed(2);

    const homeEloRating = fixture.ratings.home_pre_elo.toFixed(0);
    const awayEloRating = fixture.ratings.away_pre_elo.toFixed(0);
    const homePointDiffRating = fixture.ratings.home_point_diff_rating.toFixed(0);
    const awayPointDiffRating = fixture.ratings.away_point_diff_rating.toFixed(0);

    const moneylineInsights = generateAnalysis(
      modelProbabilities,
      "moneyline",
      fixture,
      {
        homeEloWinProb,
        awayEloWinProb,
        homeMoneylineProb,
        awayMoneylineProb,
        homeEloSpreadProb,
        awayEloSpreadProb,
        homeSpreadProb,
        awaySpreadProb,
        homeEloRating,
        awayEloRating
      }
    );
  
    const spreadInsights = generateAnalysis(
      modelProbabilities,
      "spread",
      fixture,
      {
        homeEloWinProb,
        awayEloWinProb,
        homeMoneylineProb,
        awayMoneylineProb,
        homeEloSpreadProb,
        awayEloSpreadProb,
        homeSpreadProb,
        awaySpreadProb,
        homeEloRating,
        awayEloRating
      }
    );

    const overInsights = generateAnalysis(
      modelProbabilities,
      "over",
      fixture,
      {
        homeEloWinProb,
        awayEloWinProb,
        homeMoneylineProb,
        awayMoneylineProb,
        homeEloSpreadProb,
        awayEloSpreadProb,
        homeSpreadProb,
        awaySpreadProb,
        homeEloRating,
        awayEloRating
      }
    );

    const eloInsights = generateAnalysis(
      modelProbabilities,
      "elo",
      fixture,
      {
        homeEloWinProb,
        awayEloWinProb,
        homeMoneylineProb,
        awayMoneylineProb,
        homeEloSpreadProb,
        awayEloSpreadProb,
        homeSpreadProb,
        awaySpreadProb,
        homeEloRating,
        awayEloRating
      }
    );

    const AnimatedCell = ({ children }) => (
      <motion.div
        key={displayMode}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.5,
          scale: { type: "spring", damping: 10, stiffness: 100, bounce: 0.5 },
        }}>
        {children}
      </motion.div>
    );
    

    return (
      <div
        ref={containerRef}
        style={{
          overflowY: "scroll",
          height: "100vh",
          scrollSnapType: "y mandatory",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative", 
            padding: 0, 
            margin: 0,         
          }}>

          {isTutorialMode && (
            <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start",   position: "relative", padding: 0, margin: 0 }}>
              <ul
                style={{
                  fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                  marginBottom: "20px",
                  textAlign: "left",
                  listStyleType: "none",
                  paddingLeft: "0",
                  width: "100%",
                }}>
                <li>Swipe up to navigate</li>
              </ul>

              <div style={{
                position: "absolute",
                bottom: "25vh",
                left: "50%",
                transform: "translateX(-50%)",
                margin: 0,
                padding: 0,
              }}>
                <motion.svg 
                  width="80" 
                  height="80" 
                  viewBox="0 0 24 24"
                  animate={{ 
                    y: [-80, 0, -80],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}>
                  <path 
                    d="M12 3l8 8h-6v10h-4v-10h-6z"
                    fill="currentColor"
                  />
                </motion.svg>
              </div>
            </section>
          )}

          {isTutorialMode && (
            <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start",   position: "relative", padding: 0, margin: 0 }}>
              <ul
                style={{
                  fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                  marginBottom: "20px",
                  textAlign: "left",
                  listStyleType: "none",
                  paddingLeft: "0",
                  width: "100%",
                }}>
                <li>• Tap the table to toggle between modes</li>
                <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                  <li>◦ Probabilities mode - show the probability predicted by the model</li>
                  <li>◦ EV (Expected Value) - the difference between the predicted probability and the market's probability</li>
                  <li>◦ Star mode - for fast evaluation of of EV</li>
                </ul>
                <li>
                  • Star ratings
                  <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                    <li>◦ ⭐ – Slight or no edge, we agree with the market pricing</li>
                    <li>◦ ⭐⭐ – Moderate value bet, we think it's a good bet for the price that the market offers</li>
                    <li>◦ ⭐⭐⭐ – High value bet, we think it has a much higher probability of success than the market has priced it</li>
                    <li>
                      ◦ Keep in mind that these reflect the EV. It is recommended to change to Probabilities mode to see the actual
                      probabilities and get a better understanding of the risks involved.
                    </li>
                  </ul>
                </li>
                <li>• How to interpret the data</li>
                <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                  <li>◦ Pay attention to whether the models agree with each other</li>
                  <li>◦ Use the vig to gauge the market's confidence in their model's predictions</li>
                </ul>
                <li>• Elo Ratings</li>
                <ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
                  <li>◦ Elo Ratings are tuned using Bayesian optimization to provide the most accurate ratings possible</li>
                </ul>
              </ul>
            </section>
          )}

          {isTutorialMode && (
            <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start",   position: "relative", padding: 0, margin: 0 }}>
              <div style={{
                marginBottom: "20px",
                textAlign: "center",
                padding: "20px"
              }}>
                <p style={styles.analysis}>
                  Done with the tutorial? Tap the button below to disable. You can always re-enable in the sidebar menu or in the profile page
                </p>
              </div>
              <Card 
                className="cardbutton" 
                style={{...styles.cardbutton, width: '100%', marginBottom: "20px"}} 
                onClick={toggleTutorialMode}
              >
                <Card.Text>Disable Tutorial Mode</Card.Text>
              </Card>
            </section>
          )}

          {/* Moneyline Group */}
          <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
            <Table bordered className="main" onClick={toggleDisplayMode}>
              <tbody>
                <tr style={styles.Cells}>
                  <td colSpan="4" className="Cells" style={styles.column_1}>
                    Moneyline
                  </td>
                </tr>
                <tr>
                  <td className="Cells" style={styles.column_1}>
                    Model
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    {fixture.home_team}
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    {fixture.away_team}
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Elo
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    <AnimatedCell>
                    {displayMode === 2 ? (
                      <StarRating rating={calculateStars(parseFloat(homeEloEV), parseFloat(awayEloEV))} />
                    ) : displayMode === 0 ? (
                      `${homeEloWinProb}%`
                    ) : (
                      `${homeEloEV > 0 ? '+' : ''}${homeEloEV}%`
                    )}
                    </AnimatedCell>
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    <AnimatedCell>
                    {displayMode === 2 ? (
                      <StarRating rating={calculateStars(parseFloat(awayEloEV), parseFloat(homeEloEV))} />
                    ) : displayMode === 0 ? (
                      `${awayEloWinProb}%`
                    ) : (
                      `${awayEloEV > 0 ? '+' : ''}${awayEloEV}%`
                    )}
                    </AnimatedCell>
                  </td>
                </tr>
                {modelProbabilities.map(({ model, homeWinProb, awayWinProb, homeWinEV, awayWinEV }) => (
                  <tr key={`win_${model}`} style={styles.Cells}>
                    <td className="Cells" style={styles.column_1}>
                      {model}
                    </td>
                    <td className="Cells" style={styles.Cells}>
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(homeWinEV), parseFloat(awayWinEV))} />
                      ) : displayMode === 0 ? (
                        `${homeWinProb}%`
                      ) : (
                        `${homeWinEV > 0 ? '+' : ''}${homeWinEV}%`
                      )}
                      </AnimatedCell>
                    </td>
                    <td className="Cells" style={styles.Cells}>
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(awayWinEV), parseFloat(homeWinEV))} />
                      ) : displayMode === 0 ? (
                        `${awayWinProb}%`
                      ) : (
                        `${awayWinEV > 0 ? '+' : ''}${awayWinEV}%`
                      )}
                      </AnimatedCell>
                    </td>
                  </tr>
                ))}
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Market
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {homeMoneylineProb}%
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {awayMoneylineProb}%
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Moneyline Vig
                  </td>
                  <td className="Cells" style={styles.column_1}></td>
                  <td colSpan="2" className="Cells" style={styles.Cells}>
                    {moneylineVig}%
                  </td>
                </tr>
              </tbody>
            </Table>

            <div style={{ marginTop: "10vh" }}>
              {moneylineInsights.length > 0 && (
                <ul style={{ 
                  listStyleType: "none", 
                  padding: 0,
                  margin: 0 
                }}>
                  {moneylineInsights.map((insight, i) => (
                    <li key={i} style={styles.analysis}>
                      • {insight}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>

          {/* Spread Group */}
          <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
            <Table bordered className="main" onClick={toggleDisplayMode}>
              <tbody>
                <tr style={styles.Cells}>
                  <td colSpan="4" className="Cells" style={styles.column_1}>
                    Spread
                  </td>
                </tr>
                <tr>
                  <td className="Cells" style={styles.column_1}>
                    Model
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    {fixture.home_team}
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    {fixture.away_team}
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Elo
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    <AnimatedCell>
                    {displayMode === 2 ? (
                      <StarRating
                        rating={calculateStars(
                          parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb),
                          parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb)
                        )}
                      />
                    ) : displayMode === 0 ? (
                      `${homeEloSpreadProb}%`
                    ) : (
                      `${(parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb)).toFixed(2) > 0 ? '+' : ''}${(parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb)).toFixed(2)}%`
                    )}
                    </AnimatedCell>
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    <AnimatedCell>
                    {displayMode === 2 ? (
                      <StarRating
                        rating={calculateStars(
                          parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb),
                          parseFloat(homeEloSpreadProb) - parseFloat(homeSpreadProb)
                        )}
                      />
                    ) : displayMode === 0 ? (
                      `${awayEloSpreadProb}%`
                    ) : (
                      `${(parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb)).toFixed(2) > 0 ? '+' : ''}${(parseFloat(awayEloSpreadProb) - parseFloat(awaySpreadProb)).toFixed(2)}%`
                    )}
                    </AnimatedCell>
                  </td>
                </tr>
                {modelProbabilities.map(({ model, homeCoverProb, awayCoverProb, homeCoverEV, awayCoverEV }) => (
                  <tr key={`cover_${model}`} style={styles.Cells}>
                    <td className="Cells" style={styles.column_1}>
                      {model}
                    </td>
                    <td className="Cells" style={styles.Cells}> 
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(homeCoverEV), parseFloat(awayCoverEV))} />
                      ) : displayMode === 0 ? (
                        `${homeCoverProb}%`
                      ) : (
                        `${homeCoverEV > 0 ? '+' : ''}${homeCoverEV}%`
                      )}
                      </AnimatedCell>
                    </td>
                    <td className="Cells" style={styles.Cells}>
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(awayCoverEV), parseFloat(homeCoverEV))} />
                      ) : displayMode === 0 ? (
                        `${awayCoverProb}%`
                      ) : (
                        `${awayCoverEV > 0 ? '+' : ''}${awayCoverEV}%`
                      )}
                      </AnimatedCell>
                    </td>
                  </tr>
                ))}
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Market
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {homeSpreadProb}%
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {awaySpreadProb}%
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Spread Vig
                  </td>
                  <td className="Cells" style={styles.column_1}></td>
                  <td colSpan="2" className="Cells" style={styles.Cells}>
                    {spreadVig}%
                  </td>
                </tr>
              </tbody>
            </Table>

            <div style={{ marginTop: "10vh" }}>
              {spreadInsights.length > 0 && (
                <ul style={{ 
                  listStyleType: "none", 
                  padding: 0,
                  margin: 0 
                }}>
                  {spreadInsights.map((insight, i) => (
                    <li key={i} style={styles.analysis}>
                      • {insight}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>

          {/* Over/Under Group */}
          <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
            <Table bordered className="main" onClick={toggleDisplayMode}>
              <tbody>
                <tr style={styles.Cells}>
                  <td colSpan="4" className="Cells" style={styles.column_1}>
                    Over/Under
                  </td>
                </tr>
                <tr>
                  <td className="Cells" style={styles.column_1}>
                    Model
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    Over
                  </td>
                  <td className="Cells" style={styles.column_1}>
                    Under
                  </td>
                </tr>
                {modelProbabilities.map(({ model, overProb, underProb, overEV, underEV }) => (
                  <tr key={`ou_${model}`} style={styles.Cells}>
                    <td className="Cells" style={styles.column_1}>
                      {model}
                    </td>
                    <td className="Cells" style={styles.Cells}>
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(overEV), parseFloat(underEV))} />
                      ) : displayMode === 0 ? (
                        `${overProb}%`
                      ) : (
                        `${overEV > 0 ? '+' : ''}${overEV}%`
                      )}
                      </AnimatedCell> 
                    </td>
                    <td className="Cells" style={styles.Cells}>
                      <AnimatedCell>
                      {displayMode === 2 ? (
                        <StarRating rating={calculateStars(parseFloat(underEV), parseFloat(overEV))} />
                      ) : displayMode === 0 ? (
                        `${underProb}%`
                      ) : (
                        `${underEV > 0 ? '+' : ''}${underEV}%`
                      )}
                      </AnimatedCell>
                    </td>
                  </tr>
                ))}
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Market
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {overProb}%
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {underProb}%
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Totals Vig
                  </td>
                  <td className="Cells" style={styles.column_1}></td>
                  <td colSpan="2" className="Cells" style={styles.Cells}>
                    {totalsVig}%
                  </td>
                </tr>
              </tbody>
            </Table>

            <div style={{ marginTop: "10vh" }}>
              {overInsights.length > 0 && (
                <ul style={{ 
                  listStyleType: "none", 
                  padding: 0,
                  margin: 0 
                }}>
                  {overInsights.map((insight, i) => (
                    <li key={i} style={styles.analysis}>
                      • {insight}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>

          {/* Other Ratings Group */}
          <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
            <Table bordered className="main">
              <tbody>
                <tr style={styles.Cells}>
                  <td colSpan="4" className="Cells" style={styles.column_1}>
                    Other Ratings
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Elo
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {homeEloRating}
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {awayEloRating}
                  </td>
                </tr>
                <tr style={styles.Cells}>
                  <td className="Cells" style={styles.column_1}>
                    Point Differential Rating
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {homePointDiffRating}
                  </td>
                  <td className="Cells" style={styles.Cells}>
                    {awayPointDiffRating}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{ marginTop: "10vh" }}>
              {eloInsights.length > 0 && (
                <ul style={{ 
                  listStyleType: "none", 
                  padding: 0,
                  margin: 0 
                }}>
                  {eloInsights.map((insight, i) => (
                    <li key={i} style={styles.analysis}>
                      • {insight}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>

          {/* Close Button Section */}
          <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
            <Card className="cardbutton" style={{...styles.cardbutton, width: '100%'}} onClick={onClose}>
              <Card.Text>Close Projections</Card.Text>
            </Card>
          </section>
        </div>
      </div>
    );
  }

  return null;
}

export default Projections;
