import React from 'react';

const Versus = ({ fixture }) => {
  if (isSupportedSport(fixture) && fixture.stats)
   {
    return (
      <span>
        {fixture.home_team +
          "(" +
          fixture.stats.home_wins +
          "-" +
          fixture.stats.home_losses +
          "-" +
          fixture.stats.home_draws +
          ")" + 
          "(" +
          fixture.ratings.home_pre_elo.toFixed(0) +
          ") " }
        vs{' '}
        {
          fixture.away_team +
          "(" +
          fixture.stats.away_wins +
          "-" +
          fixture.stats.away_losses +
          "-" +
          fixture.stats.away_draws +
          ")" + 
          "(" +
          fixture.ratings.away_pre_elo.toFixed(0) +
          ")" 
          }
      </span>
    );
  }
  else if (fixture.stats) {
    return (
      <span>
      {fixture.home_team +
        "(" +
        fixture.stats.home_wins +
        "-" +
        fixture.stats.home_losses +
        ")" +
        "(" +
        fixture.ratings.home_pre_elo.toFixed(0) +
        ") " }
      vs{' '}
      {
        fixture.away_team +
        "(" +
        fixture.stats.away_wins +
        "-" +
        fixture.stats.away_losses +
        ")" +
        "(" +
        fixture.ratings.away_pre_elo.toFixed(0) +
        ")" 
        }
    </span>
    );
  }
  
  return (
    <span>
      {fixture.home_team} vs {fixture.away_team}
    </span>
  );
};

function isSupportedSport(fixture) {
  const supportedSports = ["Hockey", "Soccer", "RugbyLeague", "AussieRules"];
  return supportedSports.includes(fixture.sport);
}
export default Versus;