import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAppState } from '../AppStateContext';
import * as motion from "motion/react-client";
import { Oval } from 'react-loading-icons';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert
} from 'reactstrap';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAppState();

  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${url}/auth/login/`, {
        username,
        password
      });
      login(response.data.token);
      console.log('Token: ', response.data.token);
      console.log('User: ', response.data.user);
      console.log('id: ', response.data.user.pk);
      
      wait(3000);
      setIsLoading(false);
      navigate('/');

    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.non_field_errors) {
        setError('Invalid username or password');
      } else if (!error.response) {
        setError('Network error. Please check your connection');
      } else {
        setError('An error occurred. Please try again later');
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 1.5,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
      <Container className="min-vh-100 d-flex align-items-center">
        <Row className="justify-content-center w-100">
          <Col md={6}>
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1, opacity: isLoading ? 0 : 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
              }}
            >
              {!isLoading ? (
                <Card className="bg-dark text-white mb-3 py-2">
                  <CardHeader>Sign In</CardHeader>
                  <CardBody>
                    {error && <Alert color="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label for="username">Username</Label>
                        <Input
                          type="text"
                          name="username"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                          className="bg-dark text-white mb-3 py-2"
                          style={{ fontSize: '16px', height: 'auto' }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="bg-dark text-white mb-3 py-2"
                          style={{ fontSize: '16px', height: 'auto' }}
                        />
                      </FormGroup>
                      <Button type="submit" color="primary" block>Sign In</Button>
                    </Form>
                    <div className="mt-3 text-center">
                      <Link to="/passwordresetrequest">Forgot Password?</Link>
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <div className="d-flex justify-content-center">
                  <Oval strokeWidth={5}     height={100} width={100}  />
                </div>
              )}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default SignIn;