import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { styles } from "../../styles/styles.js";
import { useAppState } from "../../AppStateContext.js";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Oval } from 'react-loading-icons';

const CardButton = ({ title, onClick }) => (
  <Card 
    onClick={onClick}
    className="mb-3 game-card"
    style={{...styles.cardbutton, width: '100%'}}
  >
    <Card.Body>
      <Card.Title style={{ color: 'white', margin: 0 }}>{title}</Card.Title>
    </Card.Body>
  </Card>
);

const CountdownTimer = ({ userProfile, lastUnlock, fetchUserProfile }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const last = new Date(lastUnlock);
      const nextUnlock = new Date(last.getTime() + 2 * 60 * 1000);
      const now = new Date();
      const diff = nextUnlock - now;

      if (diff <= 0) {
        setTimeLeft('00:00:00');
        clearInterval(timer);
        fetchUserProfile(); 
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeLeft(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUnlock]);

  return <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{timeLeft}</div>;
};

const UnlockModal = ({ isOpen, toggle = () => {}, onUnlock, onUpgrade, fixture }) => {
  const [loading, setLoading] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
  const {userProfile, fetchUserProfile} = useAppState();

  const handleUnlock = async () => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 5000)); // 5 second delay
    await onUnlock();
    setLoading(false);
    setUnlocked(true);
  };

  const handleClose = () => {
    setUnlocked(false);
    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
      setUnlocked(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={unlocked ? handleClose : toggle} style={{ color: 'black' }} size="lg">
      <ModalHeader toggle={unlocked ? handleClose : toggle} style={{ color: 'white', backgroundColor: 'black' }}>
        {unlocked ? 'Success!' : 'Unlock This Game'}
      </ModalHeader>
      <ModalBody style={{ color: 'white', backgroundColor: 'black', textAlign: 'center' }}>
        {loading ? (
          <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
            <Oval strokeWidth={5} height={100} width={100} />
          </div>
        ) : unlocked ? (
          <div className="text-center">
            <h4>Game Successfully Unlocked!</h4>
            <p>You now have access to all predictions and insights for this game.</p>
            <p>Click the button below to return to the game.</p>
          </div>
        ) : (
          <>
            {/* Daily Unlock Status Section */}
            <div className="mb-4">
              {userProfile.can_unlock ? (
                <>
                  <h4>Free Daily Unlock Available!</h4>
                  <p>As a free user, you get one fixture unlock per day. This will give you access to all predictions and insights for this game, along with the comment section.</p>
                  <p>Out of unlocks? Feel free to check out the stats and h2h data which are free for everyone!</p>
                </>
              ) : (
                <>
                  <h4>Daily Unlock Used</h4>
                  <p>You'll get another free unlock in:</p>
                  <CountdownTimer lastUnlock={userProfile.last_unlock} userProfile={userProfile} fetchUserProfile={fetchUserProfile} />
                  <p>Want unlimited access? Upgrade to Pro!</p>
                </>
              )}
            </div>

            {/* Why Upgrade Section */}
            <div>
              <h4>Why Upgrade to Pro?</h4>
              <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                <li>Unlimited access to all game predictions and insights</li>
                <li>Access to game specific discussion threads</li>
                <li>Advanced statistical models and projections</li>
                <li>Priority customer support</li>
                <li>No daily unlock limits</li>
              </ul>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter style={{ backgroundColor: 'black' }}>
        {unlocked ? (
          <Button color="primary" onClick={handleClose}>
            Return to Game
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={handleUnlock} disabled={loading || !userProfile.can_unlock}>
              Unlock
            </Button>
            <Button color="success" onClick={onUpgrade} disabled={loading}>
              Upgrade to Pro
            </Button>
            <Button color="secondary" onClick={toggle} disabled={loading}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

const Unlock = ({ fixture, onClose = () => {} }) => {
  const { userProfile, fetchUserProfile } = useAppState();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(true);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    onClose();
  };

  const handleUnlockConfirm = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const url = process.env.REACT_APP_API_URL;
      
      await axios.post(`${url}/profile/unlock_fixture/`, 
        { fixture_id: fixture.id },
        { headers: { Authorization: `Token ${token}` }}
      );
      console.log('Unlocked fixture:', fixture.id);
      fetchUserProfile();
    } catch (error) {
      console.error('Error unlocking fixture:', error);
      fetchUserProfile();
    }
  };

  const handleUpgrade = () => {
    toggleModal();
    navigate('/profile');
  };

  return (
    <UnlockModal 
      isOpen={modalOpen}
      toggle={toggleModal}
      onUnlock={handleUnlockConfirm}
      onUpgrade={handleUpgrade}
      fixture={fixture}
      userProfile={userProfile}
      fetchUserProfile={fetchUserProfile}
    />
  );
};

export default Unlock;