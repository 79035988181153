import React from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../AppStateContext";
import { Container, Row, Col } from "reactstrap";
import Card from "react-bootstrap/Card";
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';



const Landing = () => {
  const { isAuthenticated } = useAppState();

  return (
    <div
      className="landing-page"
      style={{ backgroundColor: "black", minHeight: "100vh", color: "white" }}
    >
      <div className="text-center py-5" style={{ backgroundColor: "black" }}>
        <div className="container">
          <pre className="ascii-art" style={{ color: "white" }}>
            {`
'  ████████ ███████ ██████  
'     ██    ██      ██   ██ 
'     ██    █████   ██████  
'     ██    ██      ██   ██ 
'     ██    ██      ██████  
'                           
'                           
      `}
          </pre>
          <p className="lead mb-4" style={{ color: "white" }}>
            Optimized betting research for everyone
          </p>
          {!isAuthenticated ? (
            <>
            <Link to="/register" className="btn btn-primary btn-lg">
              Join the beta
            </Link>
            <p>Or get started by tapping on the menu button on the top right corner of the screen! </p>
            </>
          ) : (
            <p>Welcome back!</p>
          )}
        </div>
      </div>

      <Container>

        <Row className="my-5">
          <Col xs={12} md={10} lg={10} xl={12} className="mx-auto">
          <ScrollAnimation animateIn="fadeIn">
            <h2 className="mb-4">The Pitch</h2>
            <p>
              You're probably here because you're the type of bettor who does a little bit of research
              before placing a bet.
              <br />
              <br />


              There are unlimited resources out there for a bettor to use when making decisions.
              The options range from internet searches, sports analytics sites, +EV tools, 
              podcasts, social media, etc. 
              Even official league apps have data and betting picks available.
              <br />
              <br />


              So why choose us over the rest?
              <br />
              <br />

              We emphasize UI/UX more than other sites and are constantly optimizing the user experience. 
              What we offer is a streamlined, easy to use tool that allows you to make informed decisions 
              as fast as possible. This is accomplished through the use of a flashcard-style interface, 
              where you can quickly review all of the relevant data for a given game and augment your intuition.
              <br />
              <br />

              Every sport has a common interface, once you get used to researching using one
              league, your muscle memory will carry over to the others.

              <br />
              <br />

              If you're seeing this, then you're part of the friends and family beta. 
              Just register an account and reach out to me for an upgrade code, you'll have access to the premium features for free for life!
            </p>
            </ScrollAnimation>
          </Col>
        </Row>
        <hr className="my-5" style={{ borderColor: 'white' }} />


        <Row className="my-5">

          <Col xs={12} md={10} lg={8} xl={12} className="mx-auto">
          <ScrollAnimation animateIn="fadeIn" >
            <h2 className="mb-4">Data Cheat Sheet</h2>
            <p className="mb-4">
              Researching bets is a hassle and takes a lot of time and energy. The goal of our data cheat sheet is to 
              minimize the amount of effort required to get the full picture of a matchup. 
            </p>
            <h4 className="mt-5 mb-3">Detailed Performance Breakdowns</h4>
              <Row className="mt-5 g-4">
              <Col md={4}>
                <Card className="h-100 bg-dark text-white">
                  <Card.Body>
                    <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Recent Form Analysis</Card.Title>
                    <Card.Text className="fs-3">
                      <ul className="list">
                        <li className="mb-2">Track team performance over the last 3, 5, and 10 games</li>
                        <li className="mb-2">View win-loss records, including draws for applicable sports</li>
                        <li className="mb-2">Analyze point differentials to gauge team strength</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              
              <Col md={4}>
                <Card className="h-100 bg-dark text-white">
                  <Card.Body>
                    <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Spread Coverage</Card.Title>
                    <Card.Text className="fs-6">
                      <ul className="list">
                        <li className="mb-2">See how often teams cover the spread in recent games and throughout the season</li>
                        <li className="mb-2">Compare home and away spread performance</li>
                        <li className="mb-2">Identify trends in spread coverage for more accurate predictions</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              
              <Col md={4}>
                <Card className="h-100 bg-dark text-white">
                  <Card.Body>
                    <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Over/Under Insights</Card.Title>
                    <Card.Text className="fs-6">
                      <ul className="list">
                        <li className="mb-2">Track total points scored in recent games</li>
                        <li className="mb-2">View the frequency of overs and unders for each team</li>
                        <li className="mb-2">Compare team totals against current over/under lines </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </ScrollAnimation>

            <div style={{height: '25vh'}}></div>

            <ScrollAnimation animateIn="fadeIn">
            <h4 className="mt-5 mb-3">Head-to-Head Matchup Analysis</h4>
            <ul>
              <li>Comprehensive history of previous encounters</li>
              <li>Detailed stats for wins, spreads, and totals in head-to-head games</li>
            </ul>

            <h4 className="mt-5 mb-3">Season-Long Statistics</h4>
            <ul>
              <li>Full season records for wins, losses, and draws</li>
              <li>Home and away split performance breakdowns</li>
              <li>Season-long spread coverage and over/under trends</li>
              <li>Previous season data for year-over-year comparisons (available for the first 25% of the season)</li>
            </ul>

            <h4 className="mt-5 mb-3">Advanced Metrics</h4>
            <ul>
              <li className="mb-3">
                ELO Ratings: Understand team strength beyond simple win-loss records
                <ul>
                  <li>Our ELO Ratings are tuned using Bayesian optimization to provide the most accurate ratings possible</li>
                  <li>They take into account margin of victory and change after every game</li>
                </ul>
              </li>
            </ul>
            </ScrollAnimation>
            <div style={{height: '25vh'}}></div>

            <ScrollAnimation animateIn="fadeIn">
            <Row className="mt-5 g-4">
            <Col xs={12} md={4}>
              <Card className="h-100 bg-dark text-white">
                <Card.Body>
                  <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Projections</Card.Title>
                  <Card.Text className="fs-6">
                    <ul className="list">
                      <li className="mb-2">Gain a small edge or a starting point by using our Machine Learning/AI projections</li>
                      <li className="mb-2">As a free user, you can unlock one projection every 24 hours</li>
                      <li className="mb-2">Our models aren't as robust as those used by sharps or the market yet, but we're improving them all the time</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
            <Col xs={12} md={4}>
              <Card className="h-100 bg-dark text-white">
                <Card.Body>
                  <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Suggestions</Card.Title>
                  <Card.Text className="fs-6">
                    <ul className="list">
                      <li className="mb-2">Don't know which game to watch? Explore a daily feed for each sport with the best plays.</li>
                      <li className="mb-2">As a free user, you can unlock a feed for a single sport every 24 hours</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
            <Col xs={12} md={4}>
              <Card className="h-100 bg-dark text-white">
                <Card.Body>
                  <Card.Title className="fs-4 mb-3 pb-2 border-bottom border-secondary">Community Insights</Card.Title>
                  <Card.Text className="fs-6">
                    <p className="mb-3">Read each game's discussion threads and valuable insights from sharps and experts through our Reddit-like comment interface:</p>
                    <ul className="list">
                      <li className="mb-2">Discuss strategies and share insights on individual games</li>
                      <li className="mb-2">Learn from experienced bettors and contribute your own analysis</li>
                      <li className="mb-2">Upvote valuable comments to highlight the best information</li>
                      <li className="mb-2">Track user reputation to identify consistent contributors</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          </ScrollAnimation>
          </Col>
        </Row>

        <div style={{height: '25vh'}}></div>

        <hr className="my-5" style={{ borderColor: 'white' }} />

        <Row className="my-5">
          <Col xs={12} md={10} lg={8} xl={12} className="mx-auto">
          <ScrollAnimation animateIn="fadeIn">
            <h2 className="mb-4">Premium Features</h2>
            <p className="mb-4">
              Be an early adopter and lock in a lifetime price of $20 a month.
              Get unlimited access to projections and suggestions and go ad-free!
            </p>
            <h4 className="mt-5 mb-3">Advanced Projection Models</h4>
            <p>
              Get access to projections and plus-EV bets from two
              sophisticated models
            </p>

            <p className="mt-3">
              Our models are trained extensively on historical data and have
              been validated to have a slight edge over the market. Compare
              projections across these models to identify consensus picks and
              potential value bets.
            </p>

            <h4 className="mt-5 mb-3">Positive Expected Value (EV) Betting</h4>
            <p>
              Our platform identifies positive EV bets, where the probability of
              an outcome occurring is higher than what the odds suggest. This
              strategy aims to provide a long-term edge over the market.
            </p>
            <p>
              For example, if our models project a team has a 53% chance of
              winning, but the odds imply only a 50% chance, this presents a
              positive EV betting opportunity.
            </p>
            <p>
              We auto-calculate the vig for each bet to let you know how confident
              the market is in their pricing. A higher vig indicates that the market is not 
              confident in their pricing, while a lower vig suggests that the market is more
              confident.
            </p>
          
            <h4 className="mt-5 mb-3">Confidence Rating System</h4>
            <p>
              Our intuitive star rating system reflects the models' confidence
              in each projection:
            </p>
            <ul>
              <li>⭐ : Slight or no edge, we agree with the market pricing</li>
              <li>⭐⭐ : Moderate value bet, we think it's a good bet for the price that the market offers</li>
              <li>⭐⭐⭐ : High value bet, we think it has a much higher probability of success than the market has priced it</li>
            </ul>
            <p className="mt-3">
              This system helps you quickly identify the most promising betting
              opportunities according to our models.
            </p>
            <p className="mt-5">
              Unlock these premium features today for just $20 a month and take
              your sports betting to the next level!
            </p>
            </ScrollAnimation>
          </Col>
        </Row>


        <Row className="my-5">
        <Col xs={12} md={10} lg={8} xl={12} className="mx-auto">
          <ScrollAnimation animateIn="fadeIn">
            <h2 className="mb-4">Active Sports</h2>
            <p>Click the links below to get started!</p>
            <table className="table table-dark table-hover">
            <thead>
              <tr>
                <th style={{ backgroundColor: "black", border: "none" }}>League</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ 
                  backgroundColor: "black", 
                  border: "none",
                  '&:hover': {
                    backgroundColor: "#1a1a1a"
                  }
                }}>
                  <Link to="/nba" className="text-white">NBA</Link>
                </td>
              </tr>
              <tr>
                <td style={{ 
                  backgroundColor: "black", 
                  border: "none",
                  '&:hover': {
                    backgroundColor: "#1a1a1a"
                  }
                }}>
                  <Link to="/nhl" className="text-white">NHL</Link>
                </td>
              </tr>
              <tr>
                <td style={{ 
                  backgroundColor: "black", 
                  border: "none",
                  '&:hover': {
                    backgroundColor: "#1a1a1a"
                  }
                }}>
                  <Link to="/nrl" className="text-white">NRL</Link>
                </td>
              </tr>
              <tr>
                <td style={{ 
                  backgroundColor: "black", 
                  border: "none",
                  '&:hover': {
                    backgroundColor: "#1a1a1a"
                  }
                }}>
                  <Link to="/afl" className="text-white">AFL</Link>
                </td>
              </tr>
            </tbody>
          </table>
          </ScrollAnimation>
        </Col>
      </Row>
      </Container>

      <footer className="text-center py-3 mt-5">
        <p>&copy; 2025 The Form Bettor LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Landing;