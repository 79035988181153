import React from 'react';
import Card from 'react-bootstrap/Card';
import { styles } from '../styles/styles';
import { useNavigate } from "react-router-dom";
import * as motion from "motion/react-client";

const SuggestionsBox = ({ fixtures, userTier }) => {
  const navigate = useNavigate();

  if (userTier !== 'Pro' || !fixtures) {
    console.log('No suggestions to show due to user tier or no fixtures');
    return null;
  }

  // Collect all suggestions from all fixtures
  const allSuggestions = fixtures.flatMap(fixture => 
    (fixture.suggestions || []).map(suggestion => ({
      ...suggestion,
      fixture: fixture
    }))
  ).filter(item => item.text && item.score !== undefined);

  // Sort by score (highest to lowest)
  const sortedSuggestions = allSuggestions.sort((a, b) => b.score - a.score);

  if (sortedSuggestions.length === 0) {
    console.log('No suggestions to show');
    return null;
  }

  const handleClick = (fixture) => {
    navigate(`/fixture/${fixture.id}`, { state: { fixture } });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card style={{
        backgroundColor: 'black',
        marginBottom: '20px',
        border: '1px solid white'
      }}>
        <Card.Header style={{ color: 'white', borderBottom: '1px solid white' }}>
          <h5 style={{ margin: 0 }}>Top Suggestions</h5>
        </Card.Header>
        <div style={{
          maxHeight: '50vh',
          overflowY: 'auto',
          scrollSnapType: 'y mandatory'
        }}>
          {sortedSuggestions.map((suggestion, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              style={{
                scrollSnapAlign: 'start'
              }}
            >
              <Card 
                onClick={() => handleClick(suggestion.fixture)}
                style={{
                  backgroundColor: 'black',
                  border: 'none',
                  borderBottom: '1px solid rgba(255,255,255,0.2)',
                  cursor: 'pointer'
                }}
              >
                <Card.Body>
                  <Card.Text style={styles.analysis}>
                    {suggestion.text}
                  </Card.Text>
                  <div style={{ 
                    color: 'white',
                    fontSize: '0.8rem',
                    opacity: 0.8 
                  }}>
                    Score: {suggestion.score.toFixed(2)}
                  </div>
                </Card.Body>
              </Card>
            </motion.div>
          ))}
        </div>
      </Card>
    </motion.div>
  );
};

export default SuggestionsBox;