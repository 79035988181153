import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert
} from 'reactstrap';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (newPassword1 !== newPassword2) {
      setError('New passwords do not match');
      return;
    }

    try {
      const url = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('authToken'); // Assuming token is stored in localStorage
      
      const response = await axios.post(
        `${url}/auth/password/change/`,
        {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2
        },
        {
          headers: {
            Authorization: `Token ${token}`
          }
        }
      );

      console.log(response.data);
      setSuccess('Password changed successfully');
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    } catch (error) {
      if (error.response?.data?.old_password) {
        setError('Current password is incorrect');
      } else if (error.response?.data?.new_password1) {
        setError(error.response.data.new_password1[0]);
      } else if (!error.response) {
        setError('Network error. Please check your connection');
      } else {
        setError('An error occurred. Please try again later');
      }
    }
  };

  return (
    <Container className="min-vh-100 d-flex align-items-center">
      <Row className="justify-content-center w-100">
        <Col md={6}>
          <Card className="bg-dark text-white mb-3 py-2">
            <CardHeader>Change Password</CardHeader>
            <CardBody>
              {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="oldPassword">Current Password</Label>
                  <Input
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="newPassword1">New Password</Label>
                  <Input
                    type="password"
                    name="newPassword1"
                    id="newPassword1"
                    value={newPassword1}
                    onChange={(e) => setNewPassword1(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="newPassword2">Confirm New Password</Label>
                  <Input
                    type="password"
                    name="newPassword2"
                    id="newPassword2"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    required
                    className="bg-dark text-white mb-3 py-2"
                    style={{ fontSize: '16px', height: 'auto' }}
                  />
                </FormGroup>
                <Button type="submit" color="primary" block>Change Password</Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;